import styled from 'styled-components';
import { Field } from 'formik';
import arrow from '../img/arrow.svg';

export default styled(Field)`
  background: url(${arrow});
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) center;
  border-radius: 0;
  border: 0.0625rem solid ${({ theme }) => theme.colors.lightgray};
  font-size: 1rem;
  line-height: 3.125rem;
  padding: 0 1rem;
  width: 100%;
  height: 3.125rem;
  appearance: none;
  color: ${({ theme }) => theme.colors.darkblue};
  &[data-value=''] {
    color: ${({ theme }) => theme.colors.darkgray};
  }
  &:focus {
    outline: 0.03125rem solid ${({ theme }) => theme.colors.lightblue};
    border-color: ${({ theme }) => theme.colors.lightblue};
  }
`;
