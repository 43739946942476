import React from 'react';
import styled from 'styled-components';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';
import StyledP from '../Styles/StyledP';
import Button from '../Styles/StyledButton';
import PreCourseButton from './PreCourseButton';
import SalesforceCopy from '../Shared/SalesforceCopy';
// import StyledA from '../Styles/StyledA';
// import ArrowRight from '../img/ArrowRight';

export default function PrepMaterial({ applicationId }) {
  return (
    <Card
      span={1 / 2}
      header="Prep Work"
      defaultExpanded
      id="prep-work"
    >
      <CardSection>
        <SalesforceCopy field="prepMaterial" />
      </CardSection>
    </Card>
  );
}

const ParagraphTag = styled(StyledP)`
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  font-size: 0.895rem;
  margin: 1rem 0;
`;

// const ButtonContainer = styled(StyledA)`
//   text-decoration: none;
//   color: white;
//   &:focus {
//     outline: none;
//   }
// `;

// const PreCourseButton = styled(UnstyledButton)`
//   background: ${({ theme }) => theme.colors.lightblue};
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 0.1875rem;
//   color: white;
//   width: 75%;
//   padding: 0.75rem;
//   padding-left: 1.2rem;
//   margin: 1.5rem 0;
//   font-size: 0.875rem;
//   font-weight: 600;
//   line-height: 1rem;
//   letter-spacing: 0.01625rem;
// `;

// const ArrowImg = styled(ArrowRight)`
//   margin-left: 0.75rem;
// `;
