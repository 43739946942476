import React from 'react';

export default function UserChatFilled({ fill = '#0177A9' }) {
  return (
    <svg
      width="25px"
      height="22px"
      viewBox="0 0 25 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>user-chat-1</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Application-Phone-Consultation"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="V6-Phone-Consultation_sched-confirmation"
          transform="translate(-181.000000, -88.000000)"
        >
          <g id="Group-10" transform="translate(0.000000, 86.000000)">
            <g id="user-chat-1" transform="translate(181.500000, 0.000000)">
              <g id="Frame_-_24px">
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              </g>
              <g
                id="Filled_Icons"
                transform="translate(0.000000, 2.000000)"
                fill={fill}
                fillRule="nonzero"
              >
                <path
                  d="M15.001,14 C14.849,14 14.695,13.965 14.554,13.894 C14.215,13.725 14.001,13.378 14.001,13 L14.001,11 L13.001,11 L13.001,9 L15.001,9 C15.553,9 16.001,9.448 16.001,10 L16.001,11 L18.401,9.2 C18.574,9.07 18.785,9 19.001,9 L22.001,9 L22.001,2 L12.001,2 L12.001,5 L10.001,5 L10.001,2 C10.001,0.897 10.898,0 12.001,0 L22.001,0 C23.104,0 24.001,0.897 24.001,2 L24.001,9 C24.001,10.102 23.104,11 22.001,11 L19.334,11 L15.601,13.8 C15.424,13.932 15.213,14 15.001,14 Z"
                  id="Path"
                ></path>
                <g id="Group" transform="translate(0.000000, 6.000000)">
                  <path
                    d="M8.001,8 C10.206,8 12.001,6.206 12.001,4 C12.001,1.795 10.206,0 8.001,0 C5.795,0 4.001,1.795 4.001,4 C4.001,6.206 5.795,8 8.001,8 Z"
                    id="Path"
                  ></path>
                  <path
                    d="M8.001,9 C3.29,9 0.001,11.467 0.001,15 L0.001,16 L16.001,16 L16.001,15 C16.001,11.467 12.711,9 8.001,9 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
