import styled from 'styled-components';

export default styled.legend`
  margin: 0 0 1.5rem 0;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.colors.darkgray};
  @media (min-width: 1024px){
    margin-top: 0;
  }
`;
