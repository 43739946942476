import React from 'react';
import StyledButton from '../Styles/StyledButton';
import { Link } from '@reach/router';

const SaveAndContinue = ({
  clickCb = () => {},
  asLink,
  to,
  disabled,
  closeVariant = false,
}) => {
  let linkObj = asLink ? { as: Link, to } : {};
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
      id="save-continue-button-row"
    >
      <StyledButton as={Link} to="/dashboard">
        Close
      </StyledButton>
      <StyledButton
        variant={disabled ? 'disabled' : 'application'}
        type="submit"
        onClick={clickCb}
        disabled={disabled}
        {...linkObj}
      >
        Save &amp; {closeVariant ? 'Close' : 'Continue'}
      </StyledButton>
    </div>
  );
};

export default SaveAndContinue;
