import React, { useRef, useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import StyledInput from '../Styles/StyledInput';
import StyledButton, { UnstyledButton } from '../Styles/StyledButton';
import MinusIcon from '../img/MinusIcon';
import PlusIcon from '../img/PlusIcon';
import SuccessIconComponent from '../img/SuccessIcon';
import { ErrorSpan } from '../Shared/Input';

export default function ExperienceItem({
  i,
  complete,
  remove,
  save,
  isNew,
  errors = {},
}) {
  const inputRef = useRef(null);
  const [expanded, setExpanded] = useState(isNew);
  const highlighted = expanded || (!complete && !expanded);

  useLayoutEffect(() => {
    if (expanded && inputRef.current) {
      inputRef.current.focus();
    }
  }, [expanded]);
  return (
    <StyledArticle>
      <ModifiedButton
        highlighted={highlighted}
        onClick={e => {
          e.preventDefault();
          setExpanded(value => !value);
        }}
      >
        <StyledDiv highlighted={highlighted}>
          {expanded && <MinusIcon />}
          {!expanded && complete && <SuccessIcon />}
          {!expanded && !complete && <PlusIcon />}{' '}
          <StyledH3>Add Work Experience #{i + 1}</StyledH3>
        </StyledDiv>
      </ModifiedButton>
      {expanded && (
        <>
          <ModifiedInput
            innerRef={inputRef}
            placeholder="Position"
            name={`workExperience.${i}.position`}
          />
          {errors.position && <ErrorSpan>{errors.position}</ErrorSpan>}
          <ModifiedInput
            placeholder="How many months?"
            type="text"
            name={`workExperience.${i}.duration`}
          />
          {errors.duration && <ErrorSpan>{errors.duration}</ErrorSpan>}
          <ModifiedInput
            placeholder="Brief description of your role"
            name={`workExperience.${i}.description`}
            component="textarea"
            style={{
              lineHeight: '1rem',
              paddingTop: '1rem',
              height: 'unset',
            }}
            rows={5}
          />
          {errors.description && <ErrorSpan>{errors.description}</ErrorSpan>}
          <StyledFooter
            style={{
              padding: '0 1rem',
            }}
          >
            <ButtonContainer>
              <LinkButton onClick={remove}>Remove</LinkButton>
              <LinkButton
                onClick={e => {
                  e.preventDefault();
                  save();
                  setExpanded(false);
                }}
              >
                Save
              </LinkButton>
            </ButtonContainer>
          </StyledFooter>
        </>
      )}
    </StyledArticle>
  );
}

const LinkButton = styled(UnstyledButton)`
  :hover {
    cursor: pointer;
  }
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.blue};
`;

const StyledH3 = styled.h3`
  padding: 0 1rem;
`;
const StyledArticle = styled.article`
  /* border: 1px solid ${({ theme }) => theme.colors.lightgray}; */
  margin: 2rem 0;
`;
const ModifiedButton = styled(StyledButton)`
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  padding: 0 1rem;
  font-size: 1rem;
  border-radius: 1px;
  border: ${({ theme, highlighted }) =>
    highlighted
      ? `2px solid rgba(1,119,169,1);
      font-weight: 600;`
      : `1px solid ${theme.colors.lightgray}`};
  &:focus {
    border: 2px solid rgba(1, 119, 169, 1);
  }
`;
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  ${({ highlighted, theme }) =>
    highlighted ? `color: ${theme.colors.blue};` : ''}
`;

const StyledFooter = styled.footer`
  border: 1px solid ${({ theme }) => theme.colors.lightgray};
  line-height: 3.125rem;
  outline: unset;
`;

const ModifiedInput = styled(StyledInput)`
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  -webkit-appearance: none;
  &:not(:focus) {
    border-bottom: none;
  }
  &:focus {
    border: 0.0625rem solid ${({ theme }) => theme.colors.lightgray};
    outline: none;
    border-bottom: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 3.125rem;
  padding: 1rem;
`;

const SuccessIcon = styled(SuccessIconComponent)`
  color: ${({ theme }) => theme.colors.midgreen};
`;
