import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Router } from '@reach/router';
import FinanceCenter from '../FinanceCenter/FinanceCenter';
import Onboarding from '../Onboarding/Onboarding';
import AdmissionsDecision from '../AdmissionsDecision/AdmissionsDecision';
import PrecourseWork from '../PreCourseWork/PrecourseWork';
import Party from '../Party/Party';
import Loading from '../Shared/Loading';
import DashboardContent from './DashboardContent';
import StyledSection from '../Styles/StyledSection';
import IntroMessage from './IntroMessage';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';
import { useCurrentApplication } from '../utils/hooks';
import { ReactComponent as YellowAlert } from '../img/YellowAlert.svg';
import { Redirect, Location } from '@reach/router';
import {
  useApplicationId,
  ApplicationContextDetails,
} from '../Shared/ApplicationProvider';
import _get from 'lodash/get';
import { reportCustomMessage } from '../utils/sentryUtils'
import Scholarship from '../Scholarship/Scholarship';

export default function DashboardRouter() {
  const applicationId = useApplicationId();
  const { loading, data, error } = useCurrentApplication();
  const { saveDraftLoading } = useContext(ApplicationContextDetails);
  const [ sentErrorReport, setSentErrorReport ] = useState(false);
  const cohortId = _get(data, 'application.cohortId');

  if (loading) return <Loading />;

  if (!loading && !cohortId && !saveDraftLoading) {
    return <NoApplicationsComponent />;
  }

  if(error) {
    if(!sentErrorReport) {
      reportCustomMessage(error, 'error');
      setSentErrorReport(true);
    } 

    return <ErrorRoute>An unknown error occurred. Please try again.</ErrorRoute>
  }

  return (
    <DashboardContainer>
      <Location>
        {({ location }) => {
          // Only allow these routes to be reached if application has been actually created in sf
          // In future may want to add more route specific routes to navigate away based on progress, but not super important right now
          if (
            !applicationId &&
            new RegExp(
              '/dashboard/((finance-center)|(onboarding)|(admissions-decision)|(pre-course-work)|(party))',
            ).test(location.pathname)
          )
            return <Redirect noThrow={true} to="dashboard/" />;
          return (
            <Router primary={false}>
              <DashboardContent path="/" />
              <FinanceCenter path="/finance-center" />
              <Onboarding path="/onboarding" />
              <Scholarship path="/scholarship" />
              <AdmissionsDecision path="/admissions-decision" />
              <PrecourseWork path="/pre-course-work" />
              <Party path="/party" />
            </Router>
          );
        }}
      </Location>
    </DashboardContainer>
  );
}

const DashboardContainer = styled.div`
  background: #fbfbfb;
`;

const NoApplicationsComponent = () => (
  <StyledSection variant="dashboard">
    <div>
      <IntroMessage />
      <Card
        icon={YellowAlert}
        span={2 / 3}
        collapsable={false}
        header="No Applications Found"
      >
        <CardSection style={{ paddingTop: 0 }}>
          You haven't created an application yet! Head to{' '}
          <a href="https://devmountain.com/cohorts">devmountain.com/cohorts</a>{' '}
          to find a course option that works for you.
        </CardSection>
      </Card>
    </div>
  </StyledSection>
);


const ErrorRoute = styled.div`
  margin: 2rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;