import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import { ErrorSpan, HelperText, SpacingSpan } from './Input';
import StyledSelect from '../Styles/StyledSelect';

export default function Select({helperText, ...props}) {
  return (
    <>
      <ErrorMessage name={props.name}>{() => <ErrorSpan />}</ErrorMessage>
      <StyledSelect
        name={props.name}
        component="select"
        // the data-value prop allows for some styling magic
        // to allow a "placeholder" style on a select
        data-value={props.value}
        {...props}
      >
        {props.children}
      </StyledSelect>
      {helperText && <HelperText>{helperText}</HelperText>}
      <ErrorMessage name={props.name}>
        {error => <ErrorSpan>{error}</ErrorSpan>}
      </ErrorMessage>
      <SpacingSpan>*</SpacingSpan>
    </>
  );
}

Select.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.node,
};

Select.defaultProps = {
  name: '',
  value: '',
  children: [],
};
