import React from 'react';
import styled from 'styled-components';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';

export default function FinancingOptions(props) {
  return (
    <Card span={1 / 2} header="Financing Options" defaultExpanded>
      <CardSection>
        <StyledP>
          Need help financing your coding bootcamp experience? You can use 
          whatever financing method you'd like. If you're looking for guidance 
          and more information on financing visit:{' '}
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href="https://devmountain.com/tuition-and-financing"
          >
            devmountain.com/tuition-and-financing
          </StyledA>
        </StyledP>
        <StyledP>
          Students are encouraged to find a lender that best suits their needs.
        </StyledP>
      </CardSection>
    </Card>
  );
}

const StyledP = styled.p`
  margin-bottom: 1rem;
`;

const StyledA = styled.a`
  color: ${({ theme }) => theme.colors.lightblue};
  text-decoration: none;
  font-weight: bold;
`;
