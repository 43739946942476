import React from 'react';

export default function FilledPerson(props) {
  return (
    <svg
      width="18px"
      height="19px"
      viewBox="0 0 18 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>user</title>
      <desc>Created with Sketch.</desc>
      <g
        id="progress-bar"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="progress-bar-test-three"
          transform="translate(-54.000000, -88.000000)"
        >
          <g id="Group-12" transform="translate(0.000000, 70.000000)">
            <g id="Group-11">
              <g id="Group-9">
                <g id="Group-10" transform="translate(13.000000, 16.000000)">
                  <g id="Group-32">
                    <g id="user" transform="translate(38.000000, 0.000000)">
                      <g id="Frame_-_24px">
                        <rect
                          id="Rectangle"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        ></rect>
                      </g>
                      <g
                        id="Filled_Icons"
                        transform="translate(3.000000, 2.500000)"
                        fill="#0177A9"
                        fillRule="nonzero"
                      >
                        <g id="Group">
                          <path
                            d="M9.00225,8.94788235 C11.484,8.94788235 13.50225,6.94170588 13.50225,4.47729412 C13.50225,2.01288235 11.484,0.00670588235 9.00225,0.00670588235 C6.5205,0.00670588235 4.50225,2.01288235 4.50225,4.47729412 C4.50225,6.94170588 6.521625,8.94788235 9.00225,8.94788235 Z"
                            id="Path"
                          ></path>
                          <path
                            d="M9.00225,10.0655294 C3.7035,10.0655294 0.00225,12.8227647 0.00225,16.7714118 L0.00225,17.8890588 L18.00225,17.8890588 L18.00225,16.7714118 C18.00225,12.8227647 14.302125,10.0655294 9.00225,10.0655294 Z"
                            id="Path"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
