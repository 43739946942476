import React, { useContext } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import _get from 'lodash/get';
import StyledH2 from '../Styles/StyledH2';
import Checkbox from '../Shared/Checkbox';
import Input from '../Shared/Input';
import StyledSection from '../Styles/StyledSection';
import StyledLegend from '../Styles/StyledLegend';
import { useCompletionEffect, useCurrentApplication } from '../utils/hooks';
import Select from '../Shared/Select';
import { ApplicationContextDetails } from '../Shared/ApplicationProvider';
import SaveAndContinue from '../Shared/SaveAndContinue';
import { showErrors } from '../utils/jsFunctions';
import ErrorScroll from '../Shared/ErrorScroll';
import { useValidation } from '../Shared/ValidationProvider';
import { SectionContainer, Content } from './Contact';
import { useSnackbar } from '../Shared/SnackbarProvider';

const validationSchema = Yup.object().shape({
  highestEducation: Yup.string()
    .oneOf(
      [
        'High School',
        'Some College',
        'Associates',
        'Bachelors',
        'Masters',
        'PHD',
      ],
      'Select a valid level of education',
    )
    .required('Select a valid level of education'),
  experience: Yup.object().shape({
    htmlCss: Yup.bool().required(),
    javascript: Yup.bool().required(),
    github: Yup.bool().required(),
    react: Yup.bool().required(),
    sql: Yup.bool().required(),
    node: Yup.bool().required(),
    computerScience: Yup.bool().required(),
  }),
  hoursSpent: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, 'Enter a valid number of hours.')
    .required('Enter a valid number of hours.'),
});

const setInitialValues = data => {
  const application = _get(data, 'application') || {};
  const experience = _get(application, 'experience') || {};
  return {
    highestEducation: application.highestEducation || '',
    fieldOfStudy: application.fieldOfStudy || '',
    experience: {
      htmlCss: experience.htmlCss || false,
      javascript: experience.javascript || false,
      github: experience.github || false,
      react: experience.react || false,
      sql: experience.sql || false,
      node: experience.node || false,
      computerScience: experience.computerScience || false,
    },
    hoursSpent: application.hoursSpent || '',
    hoursSpentType: application.hoursSpentType || '',
  };
};

export default function Education(props) {
  const errorContext = useValidation();
  const { localValues, setLocalValues } = useContext(ApplicationContextDetails);
  const { openSnackbar } = useSnackbar();

  // this does need to get the whole application
  // because of the useCompletionEffect
  const { data, cohort } = useCurrentApplication();
  const subject = _get(cohort, 'data.userFriendlySubject') || 'for this course';
  useCompletionEffect(data);

  return (
    <Formik
      // enableReinitialize
      initialValues={setInitialValues(data)}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        openSnackbar('Education saved', {
          closeCb: () => {
            setSubmitting(false);
            props.navigate('../experience');
          },
        });
        setLocalValues({
          ...localValues,
          application: { ...localValues.application, ...values },
        });
      }}
    >
      {formik => {
        const hasErrors = showErrors('education', formik);
        if (hasErrors && !errorContext.errors.education) {
          errorContext.setErrors('education', true);
        }
        if (!hasErrors && errorContext.errors.education) {
          errorContext.setErrors('education', false);
        }

        return (
          <Form>
            <StyledSection>
              <SectionContainer>
                <StyledH2>Education</StyledH2>
                <Content>
                  <Select
                    name="highestEducation"
                    // the value prop allows for some styling magic
                    // to allow a "placeholder" style on a select
                    value={formik.values.highestEducation}
                  >
                    <option value="" hidden disabled>
                      Highest Level of Education
                    </option>
                    <option value="High School">High School</option>
                    <option value="Some College">Some College</option>
                    <option value="Associates">Associate&apos;s</option>
                    <option value="Bachelors">Bachelor&apos;s</option>
                    <option value="Masters">Master&apos;s</option>
                    <option value="PHD">Doctorate's</option>
                  </Select>

                  <Input name="fieldOfStudy" placeholder="Field of Study" />
                  <StyledFieldset>
                    <StyledLegend>
                      Select any that you have experience with:
                    </StyledLegend>
                    <Checkbox
                      name="experience.htmlCss"
                      id="experience.htmlCss"
                      label="HTML &amp; CSS"
                    />
                    <Checkbox
                      name="experience.javascript"
                      id="experience.javascript"
                      label="Javascript"
                    />
                    <Checkbox
                      name="experience.github"
                      id="experience.github"
                      label="Github"
                    />
                    <Checkbox
                      name="experience.react"
                      id="experience.react"
                      label="React"
                    />
                    <Checkbox
                      name="experience.sql"
                      id="experience.sql"
                      label="SQL"
                    />
                    <Checkbox
                      name="experience.node"
                      id="experience.node"
                      label="Node"
                    />
                    <Checkbox
                      name="experience.computerScience"
                      id="experience.computerScience"
                      label="Computer Science"
                    />
                  </StyledFieldset>
                  <StyledFieldset>
                    <StyledLegend style={{ paddingTop: '1rem' }}>
                      Approximately how many hours have you spent studying {subject}?
                    </StyledLegend>
                    {/* This styling works on Chrome but it seems pretty fragile. */}
                    {/* It should fail relatively gracefully and just not display the hours placeholder */}

                    <StyledDiv>
                      {/* The default for an input is for the placeholder to disappear
                      after the user inputs data. This field is small and seems to confuse
                      users when they try to type over the placeholder. */}
                      <Input
                        onFocus={e => (e.target.placeholder = '')}
                        onBlur={e =>
                          !formik.values.hoursSpent
                            ? (e.target.placeholder = '5')
                            : null
                        }
                        placeholder="5"
                        name="hoursSpent"
                        style={{ textAlign: 'center', width: '4rem' }}
                      />
                      <StyledSpan>
                        Hour
                        {+formik.values.hoursSpent !== 1 && 's'}
                      </StyledSpan>
                    </StyledDiv>
                  </StyledFieldset>
                  {+formik.values.hoursSpent > 5 && (
                    <StyledFieldset>
                      <StyledLegend>
                        List all the resources — classes, tutorials, friends, or
                        more — you used in your personal study.
                      </StyledLegend>
                      <Input
                        name="hoursSpentType"
                        component="textarea"
                        style={{
                          lineHeight: '1.4375rem',
                          paddingTop: '1rem',
                          height: 'unset',
                        }}
                        rows={5}
                      />
                    </StyledFieldset>
                  )}
                  <SaveAndContinue disabled={formik.isSubmitting} />
                </Content>
              </SectionContainer>
            </StyledSection>
            <ErrorScroll />
          </Form>
        );
      }}
    </Formik>
  );
}

const StyledFieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledSpan = styled.span`
  /* this is here because the Input has an
ErrorSpan below it that throws off the 
flex align-items: center on the parent */
  color: ${({ theme }) => theme.colors.darkblue};
  top: 1.25rem;
  left: 4.5rem;
  position: absolute;
`;
