import PenPaper from './PenPaper';
import OpenLetter from './OpenLetter';
import DollarClipboardIcon from './DollarClipboardIcon';
import CheckCardIcon from './CheckCardIcon';
import PencilWithSquareIcon from './PencilWithSquareIcon';
import PartyStreamers from './PartyStreamers';

export default {
  Application: PenPaper,
  'Admissions Decision': OpenLetter,
  'Finance Center': DollarClipboardIcon,
  Onboarding: CheckCardIcon,
  'Pre-Course Work': PencilWithSquareIcon,
  'Party!': PartyStreamers,
  'Account Center': OpenLetter,
  'Scholarship Application': PartyStreamers,
};
