import React from 'react';
import StyledSection from '../Styles/StyledSection';
import { Content, SectionContainer } from './Contact';
import StyledH2 from '../Styles/StyledH2';
import StyledP from '../Styles/StyledP';
import SaveAndContinue from '../Shared/SaveAndContinue';

export default function PersonalInfoComplete() {
  return (
    <StyledSection>
      <SectionContainer>
        <StyledH2>Application Has Been Received</StyledH2>
        <Content>
          <StyledP>
            Your application has been submitted. To make changes to your
            application, please reach out to your admissions counselor or email{' '}
            <a href="mailto:admissions@devmountain.com">
              admissions@devmountain.com
            </a>
            .
          </StyledP>
        </Content>
      </SectionContainer>
      <SectionContainer>
        <Content>
          <SaveAndContinue asLink={true} to="/apply" />
        </Content>
      </SectionContainer>
    </StyledSection>
  );
}
