const theme = {
  colors: {
    black: 'rgba(37, 37, 37, 1)',
    blue: 'rgba(1,119,169,1)',
    darkblue: 'rgba(0, 48, 90, 1)', // payment button
    darkgray: 'rgba(135,135,135,1)',
    darkestgray: 'rgba(85,85,85,1)',
    lightblue: 'rgba(14,182,254,1)', // *
    lightgray: 'rgba(216, 216, 216, 1)',
    lightestgray: 'rgba(231,231,231,1)',
    midgray: 'rgba(166,166,166,1)',
    red: 'rgba(204, 18, 23, 1)',
    lightGreen: 'rgba(12,202,142,1)',
    midgreen: 'rgba(139,216,50,1)',
    white: 'rgba(255,255,255,1)',
    mediumgray: 'rgba(248,248,248,1)',
    mediumBlue: 'rgba(1,146,208,1)', // active lightblue button
  },
  fonts: {
    libFrank: 'Libre Franklin',
    rale: 'Raleway',
  },
};
export default theme;
