import React from 'react';

export default function PartyStreamers(props) {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>icon / party</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Party-Current-dashboards"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Party-party"
          transform="translate(-16.000000, -119.000000)"
        >
          <g
            id="Party-Group-5"
            transform="translate(16.000000, 119.000000)"
          >
            <g
              id="Party-Group-2"
            >
              <g
                id="Party-icon-/-party"
              >
                <g
                  id="Party-Group-2"
                >
                  <g
                    id="Party-icon"
                    fill="#0EB6FE"
                  >
                    <g
                      id="Party-Group-4"
                    >
                      <circle
                        id="Party-Oval-16"
                        cx="20"
                        cy="20"
                        r="20"
                      />
                    </g>
                  </g>
                  <g
                    id="Party-leisure-party-popper"
                    transform="translate(7.500000, 7.500000)"
                  >
                    <g
                      id="Party-Frame_-_24px"
                    >
                      <rect
                        id="Party-Rectangle"
                        x="0.001"
                        y="0.002"
                        width="24"
                        height="24"
                      />
                    </g>
                    <g
                      id="Party-Line_Icons"
                      transform="translate(2.000000, 1.000000)"
                      fill="#FFFFFF"
                      fillRule="nonzero"
                    >
                      <g
                        id="Party-Group"
                      >
                        <path
                          d="M7,5 L9,5 C9,2.795 7.205,1 5,1 L5,3 C6.104,3 7,3.898 7,5 Z"
                          id="Party-Path"
                        />
                        <path
                          d="M16,12 L16,14 C17.104,14 18,14.898 18,16 L20,16 C20,13.795 18.206,12 16,12 Z"
                          id="Party-Path"
                        />
                        <rect
                          id="Party-Rectangle"
                          transform="translate(12.500014, 2.999856) rotate(14.034854) translate(-12.500014, -2.999856) "
                          x="11.4995637"
                          y="0.93845911"
                          width="2.00090021"
                          height="4.12279438"
                        />
                        <rect
                          id="Party-Rectangle"
                          transform="translate(18.000136, 8.500106) rotate(75.983212) translate(-18.000136, -8.500106) "
                          x="17.0006615"
                          y="6.43865889"
                          width="1.9989489"
                          height="4.12289461"
                        />
                        <path
                          d="M7.242,6.191 C6.483,6.191 6.016,6.5 5.758,6.757 C5.524,6.992 0.216,19.297 0.084,19.602 C-0.081,19.978 0.002,20.417 0.293,20.707 C0.585,20.999 1.024,21.081 1.399,20.917 C1.707,20.783 14.006,15.479 14.242,15.243 C15.7,13.785 14.165,10.922 12.121,8.879 C10.465,7.221 8.594,6.191 7.242,6.191 Z M12.81,13.763 C12.059,14.089 9.887,12.302 9.293,11.707 C8.691,11.106 6.917,8.932 7.238,8.192 C7.239,8.192 7.24,8.192 7.241,8.192 C7.92,8.192 9.328,8.914 10.707,10.294 C12.317,11.902 12.825,13.301 12.81,13.763 Z M6.08,10.852 C6.559,11.645 7.188,12.43 7.88,13.122 C8.618,13.861 9.398,14.466 10.157,14.92 L2.934,18.067 L6.08,10.852 Z"
                          id="Party-Shape"
                        />
                        <rect
                          id="Party-Rectangle"
                          transform="translate(16.999854, 4.000153) rotate(44.991897) translate(-16.999854, -4.000153) "
                          x="16.0003632"
                          y="2.58616705"
                          width="1.99898085"
                          height="2.82797291"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
