import React from 'react';

export default function DollarClipboardIcon(props) {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>tasks icon finance</title>
      <desc>Created with Sketch.</desc>
      <g
        id="DollarClipboardIcon-svgs-jonathan"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="DollarClipboardIcon-Artboard"
          transform="translate(-159.000000, -141.000000)"
        >
          <g
            id="DollarClipboardIcon-tasks-icon-finance"
            transform="translate(159.000000, 141.000000)"
          >
            <g id="DollarClipboardIcon-icon" fill="#0EB6FE">
              <circle id="DollarClipboardIcon-Oval-16" cx="20" cy="20" r="20" />
            </g>
            <g
              id="DollarClipboardIcon-file-tasks-dollar"
              transform="translate(8.000000, 8.000000)"
            >
              <g id="DollarClipboardIcon-Frame_-_24px">
                <rect
                  id="DollarClipboardIcon-Rectangle"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                />
              </g>
              <g
                id="DollarClipboardIcon-Line_Icons"
                transform="translate(3.000000, 0.000000)"
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <g id="DollarClipboardIcon-Group">
                  <path
                    d="M10,10 L8,10 L8,11.05 C6.86,11.282 6,12.293 6,13.5 C6,14.878 7.122,16 8.5,16 L9.5,16 C9.776,16 10,16.225 10,16.5 C10,16.776 9.776,17 9.5,17 L6,17 L6,19 L8,19 L8,20 L10,20 L10,18.95 C11.14,18.717 12,17.708 12,16.5 C12,15.122 10.878,14 9.5,14 L8.5,14 C8.224,14 8,13.776 8,13.5 C8,13.225 8.224,13 8.5,13 L12,13 L12,11 L10,11 L10,10 Z"
                    id="DollarClipboardIcon-Path"
                  />
                  <path
                    d="M16,5 L14,5 L14,3 L12.858,3 C12.411,1.281 10.858,0 9,0 C7.142,0 5.589,1.281 5.142,3 L4,3 L4,5 L2,5 C0.897,5 0,5.898 0,7 L0,21 C0,22.103 0.897,23 2,23 L16,23 C17.103,23 18,22.103 18,21 L18,7 C18,5.898 17.103,5 16,5 Z M9,2 C9.739,2 10.385,2.402 10.731,3 L7.268,3 C7.615,2.402 8.261,2 9,2 Z M6,5 L12,5 L12,7 L6,7 L6,5 Z M16,21 L2,21 L2,7 L4,7 L4,9 L14,9 L14,7 L16,7 L16,21 Z"
                    id="DollarClipboardIcon-Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
