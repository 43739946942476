import React, { useEffect, useState, useRef } from 'react';
import * as Sentry from '@sentry/browser';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Loading from '../Shared/Loading';
import StyledSection from '../Styles/StyledSection';
import ConsultationConfirmation from './ConsultationConfirmation';
import { GET_APPLICATION } from '../Graphql/queries';
import { useApplicationId } from '../Shared/ApplicationProvider';
import SaveAndContinue from '../Shared/SaveAndContinue';
import styled from 'styled-components';
import _get from 'lodash/get';
import HubspotIframe from './HubspotIframe';

const GET_USER = gql`
  query GetUser($id: String!) {
    user {
      first_name
      last_name
      email
      id
    }
    application(id: $id) {
      id
      consultation {
        scheduled
      }
      phone
      cohort {
        Id
        Admissions_Consultation_Booking_Link__c
      }
    }
  }
`;

function ConsultationIframe(props) {
  const applicationId = useApplicationId();
  const [details, setDetails] = useState({ loaded: false });

  const { data, loading, error } = useQuery(GET_USER, {
    variables: { id: applicationId },
    skip: !applicationId,
  });

  const iframe = useRef(null);
  const [query] = useLazyQuery(GET_APPLICATION, {
    variables: { id: applicationId },
    fetchPolicy: 'network-only',
  });

  let selectedDomain = _get(
    data,
    'application.cohort.Admissions_Consultation_Booking_Link__c',
    process.env.REACT_APP_SCHEDULE_ONCE_FORM,
  );

  // This value might come back as null in which case it reloads the entire page in the iframe
  if (!selectedDomain)
    selectedDomain = process.env.REACT_APP_SCHEDULE_ONCE_FORM;

  const isScheduleOnce = !!selectedDomain.match(/oncehub/gi);
  const isHubSpot = !!selectedDomain.match(/hubspot/gi);

  // this will be used to catch the confirmation data from schedule once
  // we can use it to redirect to a confirmation page
  useEffect(() => {
    if (!isScheduleOnce) {
      return;
    }
    const handleMessage = event => {
      const location =
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:3601'
          : process.env.REACT_APP_SERVER_URL;
      if (
        event.origin === location &&
        event.source === (iframe.current || {}).contentWindow
      ) {
        const params = Object.fromEntries(new URLSearchParams(event.data));
        setDetails({ loaded: true, ...params });
        // Not sure if this is a good idea, but this is so
        // we can update Apollo with the confirmation info
        // otherwise it will show the booking screen again

        setTimeout(() => {
          query();
        }, 5000);
      }
    };
    window.addEventListener('message', handleMessage);
  }, [isScheduleOnce, query]);

  if (loading || !data || !data.application) {
    return props.hidden ? null : <Loading />;
  }

  if (error) return 'Error';

  if (!isScheduleOnce && !isHubSpot) {
    Sentry.captureException('Consultation link does not match ScheduleOnce or HubSpot: ' + selectedDomain);
  }

  if (isHubSpot) {
    return <ContainerSection hidden={props.hidden}>
      <HubspotIframe />
    </ContainerSection>
  }


  return (
    <>
      <ContainerSection hidden={props.hidden}>
        <div>
          {!_get(data, 'application.consultation.scheduled') &&
            !details.loaded && (
              <iframe
                ref={iframe}
                // this key is a bad hack that should be changed.
                // there is js on the scheduleonce page that
                // changes its layout based on the screen size
                // and our display: none wreaks havoc with it.
                // if you originally land on /consultation first,
                // everything is fine. But if you land on /personal-info
                // and then navigate in React to /consulation,
                // the calendar isn't visible anymore.
                // We tried using translateX to move the iframe off
                // the screen, but then it steals input focus.
                // The key makes React mount and unmount the iframe.
                // This probably kills any load time gains that we were
                // hoping to get by preloading it anyway.
                key={props.hidden}
                id="schedule-once-iframe"
                title="Schedule your consultation"
                style={{ height: '75vh', width: '100%' }}
                src={`${selectedDomain}?name=${_get(
                  data,
                  'user.first_name',
                  '',
                )}%20${_get(data, 'user.last_name', '')}&email=${_get(
                  data,
                  'user.email',
                  '',
                )}&phone=${_get(
                  data,
                  'application.phone',
                  '',
                )}&application_id=${applicationId}`}
              ></iframe>
            )}

          {(_get(data, 'application.consultation.scheduled') ||
            details.loaded) && <ConsultationConfirmation details={details} />}
        </div>
        {!_get(data, 'application.consultation.scheduled') && !details.loaded && (
          <ButtonSection>
            <SaveAndContinue
              disabled
              onClick={() => props.navigate('../skill-review')}
            />
          </ButtonSection>
        )}
      </ContainerSection>
    </>
  );
}

export default ConsultationIframe;

const ButtonSection = styled(StyledSection)`
  min-height: unset;
  @media (min-width: 1024px) {
    padding-right: 1.5rem;
  }
`;

const ContainerSection = styled(StyledSection)`
  padding: 0;
  display: ${({ hidden }) => (hidden ? 'none' : 'initial')};
`;
