import { Link } from '@reach/router';
import React from 'react';
import styled from 'styled-components';
import Card from '../Shared/Card';
import DashboardNavigation from '../Shared/DashboardNavigation';
import StyledButton from '../Styles/StyledButton';
import StyledA from '../Styles/StyledA';
import StyledSection from '../Styles/StyledSection';

export default function ScholarshipRejected() {
  return (
    <StyledSection variant="dashboard">
      <div>
        <DashboardNavigation section="Scholarship Application" />
        <Card span={2 / 3} collapsable={false}>
          <CardSection>
            <p>
              We are impressed by your determination and achievement in the face
              of great adversity. Unfortunately, your entry was not chosen to
              receive the funding. However, we still have future scholarships
              available! If you're still interested, you can
              <StyledA href="https://devmountain.com/scholarships">
                {' '}
                apply for another scholarship{' '}
              </StyledA>
              or{' '}
              <StyledA href="https://devmountain.com/tuition-and-financing">
                {' '}
                check out our financing page{' '}
              </StyledA>
              to learn how you can take matters into your own hands. (Most
              students who need a little extra help to get started are able to
              find it.)
            </p>

            <div style={{ padding: '.5rem 0 2rem 0' }}>
              <StyledButton variant="secondary" leftIcon as={Link} to="..">
                back to dashboard
              </StyledButton>
            </div>
          </CardSection>
        </Card>
      </div>
    </StyledSection>
  );
}

const CardSection = styled.section`
  padding: 0 1rem;
  line-height: 1.375rem;
  font-size: 0.875rem;
  strong {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkestgray};
  }
`;
