import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import SaveAndContinue from '../Shared/SaveAndContinue';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import * as Yup from 'yup';
import StyledSection from '../Styles/StyledSection';
import StyledH2 from '../Styles/StyledH2';
import StyledLegend from '../Styles/StyledLegend';
import Checkbox from '../Shared/Checkbox';
import Acknowledgement from './Acknowledgement';
import Input from '../Shared/Input';
import Loading from '../Shared/Loading';
import { CREATE_APPLICATION } from '../Graphql/mutations';
import { getUtmParams, showErrors } from '../utils/jsFunctions';
import ErrorScroll from '../Shared/ErrorScroll';
import { GET_APPLICATION, GET_USER } from '../Graphql/queries';
import { useCompletionEffect } from '../utils/hooks';
import { useSnackbar } from '../Shared/SnackbarProvider';
import { useValidation } from '../Shared/ValidationProvider';
import { ApplicationContextDetails } from '../Shared/ApplicationProvider';
import { SectionContainer, Content } from './Contact';
import { submittedApplicationTracking } from '../utils/jsFunctions';
import { useCurrentApplication } from '../utils/hooks';
import { reportCustomMessage } from '../utils/sentryUtils';
import _get from 'lodash/get';

const setInitialValues = data => {
  let goalsVal = _get(data, 'application.goals', '');
  if (!goalsVal && typeof goalsVal !== 'string') goalsVal = '';
  const hearAbout = _get(data, 'application.hearAbout', {});
  return {
    goals: goalsVal,
    hearAbout: {
      google: hearAbout.google || false,
      googleDetail: hearAbout.googleDetail || '',
      googleBusiness: hearAbout.googleBusiness || false,
      bingYahoo: hearAbout.bingYahoo || false,
      bingYahooDetail: hearAbout.bingYahooDetail || '',
      reddit: hearAbout.reddit || false,
      discord: hearAbout.discord || false,
      quora: hearAbout.quora || false,
      advertisement: hearAbout.advertisement || false,
      internet: hearAbout.internet || false,
      internetDetail: hearAbout.internetDetail || '',
      article: hearAbout.article || false,
      alumni: hearAbout.alumni || false,
      instructor: hearAbout.instructor || false,
      currentStudent: hearAbout.currentStudent || false,
      work: hearAbout.work || false,
      codingBasics: hearAbout.codingBasics || false,
      careerKarma: hearAbout.careerKarma || false,
      reviewsSite: hearAbout.reviewsSite || false,
      campus: hearAbout.campus || false,
      strayerCapella: hearAbout.strayerCapella || false,
      traversyMedia: hearAbout.traversyMedia || false,
      facebook: hearAbout.facebook || false,
      instagram: hearAbout.instagram || false,
      youtube: hearAbout.youtube || false,
      twitter: hearAbout.twitter || false,
      linkedin: hearAbout.linkedin || false,
      friendOrRelative: hearAbout.friendOrRelative || false,
      courseReport: hearAbout.courseReport || false,
      other: hearAbout.other || false,
      otherDetail: hearAbout.otherDetail || '',
    },
    acknowledgement: _get(data, 'application.acknowledgement') || false,
    nonEmploymentAcknowledgement: false,
  };
};

const setValidationSchema = courseType =>
  Yup.object().shape({
    goals: Yup.string()
      .ensure()
      .trim()
      .required('Enter a valid response.')
      .max(20000, 'Max character limit is 20,000'),
    hearAbout: Yup.object().shape({
      google: Yup.bool(),
      googleDetail: Yup.string(),
      googleBusiness: Yup.bool(),
      bingYahoo: Yup.bool(),
      bingYahooDetail: Yup.string(),
      reddit: Yup.bool(),
      discord: Yup.bool(),
      quora: Yup.bool(),
      advertisement: Yup.bool(),
      internet: Yup.bool(),
      internetDetail: Yup.string(),
      article: Yup.bool(),
      alumni: Yup.bool(),
      instructor: Yup.bool(),
      currentStudent: Yup.bool(),
      work: Yup.bool(),
      codingBasics: Yup.bool(),
      careerKarma: Yup.bool(),
      reviewsSite: Yup.bool(),
      campus: Yup.bool(),
      strayerCapella: Yup.bool(),
      traversyMedia: Yup.bool(),
      facebook: Yup.bool(),
      instagram: Yup.bool(),
      youtube: Yup.bool(),
      twitter: Yup.bool(),
      linkedin: Yup.bool(),
      friendOrRelative: Yup.bool(),
      courseReport: Yup.bool(),
      other: Yup.bool(),
      otherDetail: Yup.string().when('other', {
        is: true,
        then: Yup.string()
          .trim()
          .required('Enter a response.'),
        otherwise: Yup.string(),
      }),
    }),
    acknowledgement:
      courseType === 'Self-Paced'
        ? undefined
        : Yup.bool().oneOf([true], 'Read and accept the acknowledgement'),
    nonEmploymentAcknowledgement: Yup.bool().oneOf(
      [true],
      'Read and accept the acknowledgement',
    ),
  });

export default function Goals({ navigate }) {
  const userQuery = useQuery(GET_USER);
  const errorContext = useValidation();
  const { setLocalValues, setId } = useContext(ApplicationContextDetails);
  const { openSnackbar, updateSnackbar } = useSnackbar();
  const { data, loading, cohort } = useCurrentApplication();
  const lengthInWeeks = _get(cohort, 'data.lengthInWeeks');
  const courseType = _get(cohort, 'data.Type__c');
  const timeZone = _get(cohort, 'data.timeZone');

  const [saveApplication] = useMutation(CREATE_APPLICATION);
  // Just refetching applications on useMutation caused a bug with the application provider and applicationcontent. easier to get whole application then route
  const [query] = useLazyQuery(GET_APPLICATION, {
    fetchPolicy: 'network-only',
    onCompleted: result => {
      reportCustomMessage('Submitted application', 'info', {
        progressEvent: 'true',
        name: `${_get(userQuery, 'data.user.first_name')} ${_get(
          userQuery,
          'data.user.last_name',
        )}`,
        email: _get(userQuery, 'data.user.email'),
      });

      updateSnackbar('Application submitted');
      // 2. Clear local values and move on to consultation
      setLocalValues({
        application: { approachDashboard: true, cohortId: '' },
      });

      submittedApplicationTracking(
        _get(result, 'application.cohort.Location_City__c'),
        _get(result, 'application.cohort.Subject__c', 'webdev'),
      );

      navigate('/apply/consultation');
    },
    onError(err) {
      console.log('error encountered getting applications', err);
      reportCustomMessage('Error submitting application', 'error', {
        progressEvent: 'true',
        name: `${_get(userQuery, 'data.user.first_name')} ${_get(
          userQuery,
          'data.user.last_name',
        )}`,
        email: _get(userQuery, 'data.user.email'),
      });
      updateSnackbar(
        'There was an error retrieving your application. Please refresh your browser',
      );
    },
  });

  useCompletionEffect(data);

  if (loading || userQuery.loading || cohort.loading) return <Loading />;

  return (
    <Formik
      // enableReinitialize
      initialValues={setInitialValues(data)}
      validationSchema={setValidationSchema(courseType)}
      onSubmit={(values, { setSubmitting }) => {
        const utm =
        // data.application.utm may be undefined if the draft 
        // was created before we began tracking utm in drafts, 
        // or it may be null if there were no utm params when 
        // the draft was created
          data.application && data.application.utm
            ? data.application.utm
            : getUtmParams();
        const { nonEmploymentAcknowledgement, ...formFields } = values;
        const application = {
          ...data.application,
          workExperience: JSON.stringify(data.application.workExperience),
          ...formFields,
          complete: {
            personalInfo:
              errorContext.completion.contact &&
              errorContext.completion.education &&
              errorContext.completion.experience,
          },
          sfContactId: userQuery.data.user.sfId,
          utm,
        };

        saveApplication({
          variables: {
            application,
          },
        })
          .then(({ data }) => {
            // Set id so consultation can query for it
            setId(data.createApplication);

            // get new id and get application
            query({ variables: { id: data.createApplication } });
          })
          .catch(() => {
            setSubmitting(false);
            updateSnackbar(
              'There was an error encountered while saving your application',
            );
          });

        openSnackbar('Goals saved');
      }}
    >
      {formik => {
        const hasErrors = showErrors('goals', formik);
        if (hasErrors && !errorContext.errors.goals) {
          errorContext.setErrors('goals', true);
        }
        if (!hasErrors && errorContext.errors.goals) {
          errorContext.setErrors('goals', false);
        }

        return (
          <Form>
            <StyledSection>
              <SectionContainer>
                <StyledH2>Goals &amp; Outcome</StyledH2>
                <Content>
                  <StyledLegend style={{ marginTop: 0 }}>
                    Briefly describe why you are taking this course and what you
                    hope to gain.
                  </StyledLegend>
                  <Input
                    name="goals"
                    component="textarea"
                    style={{
                      lineHeight: '1rem',
                      paddingTop: '1rem',
                      height: 'unset',
                    }}
                    rows={8}
                  />
                </Content>
              </SectionContainer>
              <SectionContainer>
                <StyledH2>Additional Information</StyledH2>
                <Content>
                  <StyledLegend>
                    How did you hear about Devmountain? (Select all that apply.)
                  </StyledLegend>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <Checkbox
                        name="hearAbout.facebook"
                        id="hearAbout.facebook"
                        label="Facebook"
                      />
                      <Checkbox
                        name="hearAbout.instagram"
                        id="hearAbout.instagram"
                        label="Instagram"
                      />
                      <Checkbox
                        name="hearAbout.youtube"
                        id="hearAbout.youtube"
                        label="YouTube"
                      />
                      <Checkbox
                        name="hearAbout.twitter"
                        id="hearAbout.twitter"
                        label="Twitter"
                      />
                      <Checkbox
                        name="hearAbout.linkedin"
                        id="hearAbout.linkedin"
                        label="LinkedIn"
                      />
                      <Checkbox
                        name="hearAbout.reddit"
                        id="hearAbout.reddit"
                        label="Reddit"
                      />
                      <Checkbox
                        name="hearAbout.discord"
                        id="hearAbout.discord"
                        label="Discord"
                      />
                      <Checkbox
                        name="hearAbout.quora"
                        id="hearAbout.quora"
                        label="Quora"
                      />
                      <Checkbox
                        name="hearAbout.advertisement"
                        id="hearAbout.advertisement"
                        label="Advertisement"
                      />
                      <Checkbox
                        name="hearAbout.traversyMedia"
                        id="hearAbout.traversyMedia"
                        label="Traversy Media"
                      />
                    </div>
                    <div>
                      <Checkbox
                        name="hearAbout.article"
                        id="hearAbout.article"
                        label="Article/Blog"
                      />
                      <Checkbox
                        name="hearAbout.friendOrRelative"
                        id="hearAbout.friendOrRelative"
                        label="Friend or Relative"
                      />
                      <Checkbox
                        name="hearAbout.alumni"
                        id="hearAbout.alumni"
                        label="Alumni/Graduate"
                      />
                      <Checkbox
                        name="hearAbout.instructor"
                        id="hearAbout.instructor"
                        label="Instructor"
                      />
                      <Checkbox
                        name="hearAbout.currentStudent"
                        id="hearAbout.currentStudent"
                        label="Current Student"
                      />
                      <Checkbox
                        name="hearAbout.work"
                        id="hearAbout.work"
                        label="Work/Supervisor"
                      />

                      <Checkbox
                        name="hearAbout.courseReport"
                        id="hearAbout.courseReport"
                        label="Course Report"
                      />
                      <Checkbox
                        name="hearAbout.careerKarma"
                        id="hearAbout.careerKarma"
                        label="Career Karma"
                      />
                      <Checkbox
                        name="hearAbout.reviewsSite"
                        id="hearAbout.reviewsSite"
                        label="Reviews/Site"
                      />

                      <Checkbox
                        name="hearAbout.strayerCapella"
                        id="hearAbout.strayerCapella"
                        label="Strayer/Capella"
                      />
                    </div>
                  </div>
                  <div>
                    <Checkbox
                      name="hearAbout.codingBasics"
                      id="hearAbout.codingBasics"
                      label="Coding Basics Class"
                    />
                    <Checkbox
                      name="hearAbout.campus"
                      id="hearAbout.campus"
                      label="Campus/Location drive-by"
                    />
                    <Checkbox
                      name="hearAbout.googleBusiness"
                      id="hearAbout.googleBusiness"
                      label="Google Business listing/maps"
                    />
                    <Checkbox
                      name="hearAbout.google"
                      id="hearAbout.google"
                      label="Google Search"
                    />
                    {formik.values.hearAbout.google && (
                      <Input
                        name="hearAbout.googleDetail"
                        placeholder="What keyword(s) did you use?"
                      />
                    )}
                    <Checkbox
                      name="hearAbout.bingYahoo"
                      id="hearAbout.bingYahoo"
                      label="Bing/Yahoo Search"
                    />
                    {formik.values.hearAbout.bingYahoo && (
                      <Input
                        name="hearAbout.bingYahooDetail"
                        placeholder="What keyword(s) did you use?"
                      />
                    )}
                    <Checkbox
                      name="hearAbout.internet"
                      id="hearAbout.internet"
                      label="Internet/Online"
                    />
                    {formik.values.hearAbout.internet && (
                      <Input
                        name="hearAbout.internetDetail"
                        placeholder="What site did you find us on?"
                      />
                    )}
                    <Checkbox
                      name="hearAbout.other"
                      id="hearAbout.other"
                      label="Other"
                    />
                    {formik.values.hearAbout.other && (
                      <Input
                        name="hearAbout.otherDetail"
                        placeholder="Other details"
                      />
                    )}
                  </div>
                </Content>
              </SectionContainer>
              {courseType !== 'Self-Paced' && (
                <Acknowledgement
                  courseType={courseType}
                  lengthInWeeks={lengthInWeeks}
                  timeZone={timeZone}
                />
              )}
              <SectionContainer>
                <Content>
                  <SaveAndContinue disabled={formik.isSubmitting} />
                </Content>
              </SectionContainer>
            </StyledSection>
            <ErrorScroll />
            {/* <!-- StackAdapt Pixel Code --> */}
            {/* <noscript> */}
            {/* See https://github.com/facebook/react/issues/15238 about noscript tags in React */}
            <img
              alt=""
              height="1"
              width="1"
              style={{ display: 'none' }}
              src="https://tags.srv.stackadapt.com/conv?cid=OREEQgHjW8snWM1MTLcXVf"
            />
            {/* </noscript> */}
            {/* <!-- End StackAdapt Pixel Code --> */}
          </Form>
        );
      }}
    </Formik>
  );
}
