import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components";
import _get from 'lodash/get';
import { useApplicationId } from "../Shared/ApplicationProvider";
import gql from "graphql-tag";
import ConsultationConfirmation from "./ConsultationConfirmation";
import SaveAndContinue from "../Shared/SaveAndContinue";
import StyledSection from "../Styles/StyledSection";
import { Content, SectionContainer } from "./Contact";
import StyledH2 from "../Styles/StyledH2";
import StyledLegend from "../Styles/StyledLegend";

const GET_USER = gql`
  query GetUser($id: String!) {
    user {
      first_name
      last_name
      email
      id
    }
    application(id: $id) {
      id
      consultation {
        scheduled
      }
      cohort {
        Id
        Admissions_Consultation_Booking_Link__c
      }
    }
  }
`;

const SET_SCHEDULED_CONSULTATION = gql`
  mutation SaveApplication($application: ApplicationInput!) {
    saveApplication(application: $application){
      id
    }
  }
`;

export default function HubspotIframe(props) {
  const ref = useRef();
  const applicationId = useApplicationId();
  const [hasSaved, setHasSaved] = useState(false);

  const { data, loading, error } = useQuery(GET_USER, {
    variables: { id: applicationId },
    skip: !applicationId,
  });

  const [mutate] = useMutation(SET_SCHEDULED_CONSULTATION, {
    variables: { application: { sfApplicationId: applicationId, consultationScheduled: true } },
  });

  const scheduled = !!_get(data, 'application.consultation.scheduled')
  const firstName = _get(data, 'user.first_name') || '';
  const lastName = _get(data, 'user.last_name') || '';
  const email = _get(data, 'user.email') || '';
  const url = _get(data, 'application.cohort.Admissions_Consultation_Booking_Link__c');



  useEffect(() => {
    if (!loading && !error && !scheduled) {

      const script = document.createElement('script');
      script.type = "text/javascript";
      script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";

      document.head.appendChild(script);

      const handleMessage = event => {
        const iframe = ref.current && ref.current.querySelector('iframe');
        if (
          // todo:
          // check
          // event.origin === hubspot
          event.source === (iframe || {}).contentWindow
          && event.data && event.data.meetingBookSucceeded
        ) {
          setHasSaved(true);
          // mark it in salesforce
          mutate();
        }
      }
      window.addEventListener('message', handleMessage);

      return () => window.removeEventListener('message', handleMessage)
    }
  }, [error, loading, scheduled, mutate])


  // https://community.hubspot.com/t5/APIs-Integrations/Web-hook-trigger-for-Engagement-events-like-Meeting-etc/td-p/313903
  // this component won't load if salesforce already has consultation details
  // but it may be loaded if they reload the page before admissions can mark it
  // there's not really a good webhook or API call to make except the lasted meeting booked which 
  // may not always be reliable?
  // on mount check engagements_last_meeting_booked for contact email
  // if no value, then check 
  return (
    <ContainerSection hidden={props.hidden}>
      {_get(data, 'application.consultation.scheduled') ? <ConsultationConfirmation /> :
        <>
          <StyledSection>
            <SectionContainer>
              <StyledH2 style={{ lineHeight: '1.5626rem' }}>
                Next Step: <br /> Admissions Consultation
          </StyledH2>
              <Content>
                <StyledLegend>
                  We’re looking forward to getting to know you better through
                  a phone consultation in which we’ll discuss your background,
                  your goals, and things to expect in the program;
                  as well as answering any questions you might have.
                  Please use the calendar below to schedule time
                  for your consultation. Your admissions counselor will
                  instruct you on the next steps in the application process in this consultation.
          </StyledLegend>
              </Content>
            </SectionContainer>
            <SectionContainer>
              <StyledH2></StyledH2>
              <Content>
                <div
                  // adding these as a key so the component will be
                  // re-mounted when the data is received, hopefully
                  // will be a fix for that data not being populated
                  key={firstName + lastName + email}
                  ref={ref}
                  className="meetings-iframe-container"
                  data-src={`${url}?embed=true&firstName=${firstName
                    }&lastName=${lastName
                    }&email=${email
                    }`}></div>
                <SaveAndContinue disabled={!hasSaved} asLink={hasSaved}
                  to='/dashboard' id="hubspot-save-continue-button-row" closeVariant />
              </Content>
            </SectionContainer>
          </StyledSection>

        </>
      }
    </ContainerSection>
  )

}

const ContainerSection = styled.section`
  ${({ hidden }) => (hidden ? 'display: none' : '')};
`;
