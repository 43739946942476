import React from 'react';
import styled from 'styled-components';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';
import discordLogo from '../img/discord.svg';
import SalesforceCopy from '../Shared/SalesforceCopy';

export default function Discord() {
  const displayDiscordLogo = () => <DiscordLogo src={discordLogo} />;

  return (
    <Card
      span={1 / 3}
      header="discord"
      defaultExpanded
      icon={displayDiscordLogo}
      id="join-discord"
    >
      <CardSection>
        <SalesforceCopy component={Container} field="discordInstructions" fallback={oldStaticContent} />
      </CardSection>
    </Card>
  );
}

const DiscordLogo = styled.img`
  width: 2.125rem;
  margin-right: 0.1875rem;
`;

const Paragraph = styled.p`
  margin-bottom: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.00625rem;
`;

const Container = styled.div`
  a {
    color: ${({ theme }) => theme.colors.lightblue};
    font-weight: 500;
  }
`

const oldStaticContent = <><Paragraph>
Collaborative communication will be essential to your success here at
Devmountain. Discord is a great way for you to speak with fellow
classmates as well as get one-on-one time with your mentors and
instructors. You will utilize Discord heavily to not only get
questions answered but also amplify your remote experience.
</Paragraph>
<Paragraph>
Please follow the link below to join the Devmountain Web Server. Once
you have joined you will be automatically connected with your
classmates and mentors. There are <b>text channels</b> that you will
have access to and be able to communicate effectively with your
cohort.
<b>Voice channels</b> will also be very helpful for you to speak with
classmates for group projects and discussing homework assignments.
</Paragraph></>