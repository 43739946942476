import React from 'react';
import styled from 'styled-components';
import DirectoryContactInfo from './DirectoryContactInfo';

export default function DirectoryCard({ title, ...contactProps }) {
  return (
    <DirectoryCardContainer>
      <DirectoryHeadingContainer>
        <DirectoryHeading>{title}</DirectoryHeading>
      </DirectoryHeadingContainer>
      {/* contactProps contains the staff array that could contain either the leadInstructor
      and/or the mentor to be a null value. Here we filter out any null values and display
      what is true. */}
      {contactProps.staff
        .filter(val => val !== null && val.email)
        .map(contact => (
          <DirectoryContactInfo key={contact.id} {...contact} />
        ))}
    </DirectoryCardContainer>
  );
}

const DirectoryCardContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 0.2rem;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 1.75rem;
  border: ${({ theme }) => `2px solid ${theme.colors.lightGreen}`};
`;

const DirectoryHeadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  background-color: ${({ theme }) => theme.colors.lightGreen};
`;

const DirectoryHeading = styled.h2`
  padding: 1.25rem 0;
  font-family: Raleway;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.white};
`;
