import React from 'react';
import StyledSection from '../Styles/StyledSection';
import DashboardNavigation from '../Shared/DashboardNavigation';
import { useCurrentApplication } from '../utils/hooks';
import { useApplicationId } from '../Shared/ApplicationProvider';
import _get from 'lodash/get';
import Input from '../Shared/Input';
import { Form, Formik } from 'formik';
import StyledLegend from '../Styles/StyledLegend';
import StyledButton from '../Styles/StyledButton';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from '../Shared/SnackbarProvider';
import {captureException} from '@sentry/browser';
import { Link } from '@reach/router';

const getInitialValues = (questions = []) => {
  return questions.reduce((acc, question, index) => {
    acc['question' + index] = '';
    return acc;
  }, {});
};

const getValidationSchema = questions => {
  const schema = questions.reduce((acc, question, index) => {
    acc['question' + index] = Yup.string().required('Enter a response');
    return acc;
  }, {});
  return Yup.object().shape(schema);
};

export const SAVE_SCHOLARSHIP_RESPONSES = gql`
  mutation($applicationId: ID!, $responses: [String]!) {
    saveScholarshipResponses(
      applicationId: $applicationId
      responses: $responses
    )
  }
`;

export default function ScholarshipApplication() {
  const { openSnackbar } = useSnackbar();
  const applicationId = useApplicationId();
  const { data } = useCurrentApplication();
  const questions =
    _get(data, 'application.cohort.scholarship.questions') || [];
  const terms = _get(data, 'application.cohort.scholarship.terms') || '';
  const validationSchema = React.useMemo(() => getValidationSchema(questions), [
    questions,
  ]);
  const initialValues = React.useMemo(() => getInitialValues(questions), [
    questions,
  ]);
  const [saveScholarshipResponses, { loading: mutationLoading, called, data: mutationData}] = useMutation(SAVE_SCHOLARSHIP_RESPONSES);
  let buttonText;
  if (mutationLoading){
    buttonText = 'Submitting'
  } else if (mutationData){
    buttonText = 'Submitted'
  } else {
    buttonText = 'Submit'
  }
  return (
    <StyledSection>
      <DashboardNavigation section="Scholarship Application" />
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => {
            saveScholarshipResponses({
              variables: { applicationId, responses: Object.values(values) },
            }).then(response => {
              openSnackbar('Submitted successfully.')
            }).catch(err => {
              captureException(err);
            })
          }}
        >
          {() => (
            <Form title="scholarship application">
              {questions.map((question, index) => (
                <div key={question}>
                  <StyledLegend htmlFor={`question${index}`} as="label" style={{ fontWeight: 'bold' }}>
                    {question}
                  </StyledLegend>
                  <Input
                  disabled={called}
                    name={`question${index}`}
                    component="textarea"
                    rows={8}
                    style={{
                      lineHeight: '1rem',
                      paddingTop: '1rem',
                      height: 'unset',
                    }}
                  />
                </div>
              ))}
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <StyledButton variant="secondary" leftIcon as={Link} to=".." style={{marginRight: '1rem'}}>
                back to dashboard
              </StyledButton>
                <StyledButton disabled={mutationLoading || called} type="submit" variant="primary">
                  {buttonText}
                </StyledButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div dangerouslySetInnerHTML={{ __html: terms }} />
    </StyledSection>
  );
}
