import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import Card from '../Shared/Card';
import StarIcon from '../img/StarIcon';
import StyledButton from '../Styles/StyledButton';

function AdmissionAcceptance(props) {
  return (
    <Card
      span={2 / 3}
      header="Congratulations, Bad Ass!"
      collapsable={false}
      icon={StarIcon}
    >
      <CardSection>
        You crushed your assessments and it&apos;s our pleasure to accept your
        application.
        <em>
          Return to your Approach dashboard to complete your enrollment. Welcome
          to the Devmountain family and we can&apos;t wait to have you join us.
        </em>
        <div style={{ padding: '.5rem 0 2rem 0' }}>
          <StyledButton variant="secondary" leftIcon as={Link} to="..">
            back to dashboard
          </StyledButton>
        </div>
      </CardSection>
    </Card>
  );
}

export default AdmissionAcceptance;

const CardSection = styled.section`
  padding: 0 1rem;
  line-height: 1.375rem;
  font-size: 0.875rem;
  em {
    display: block;
    padding: 1rem 0;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkestgray};
  }
`;
