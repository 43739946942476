import styled from 'styled-components';

export default styled.h2`
  font-family: 'Libre Franklin';
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  text-transform: uppercase;
  margin: 1.5rem 0;
  line-height: 0.875rem;
  letter-spacing: 0.13px;
  font-size: 0.875rem;
  @media (min-width: 1024px) {
    text-align: ${({ desktopAlign }) => desktopAlign || 'right'};
    margin-top: 0;
    padding: 1.125rem 0;
    margin-right: ${({ marginRight }) => marginRight || '1rem'};
  }
`;
