import React, { useState } from 'react';
import styled from 'styled-components';
import { UnstyledButton } from '../Styles/StyledButton';
import CardPaymentForm from './CardPaymentForm';
import CheckPaymentInfo from './CheckPaymentInfo';
import WirePaymentInfo from './WirePaymentInfo';

export default function PaymentOptions(props) {
  const [method, setMethod] = useState('card');
  return (
    <>
      <StyledH2>Payment Method</StyledH2>
      <nav style={{ display: 'flex' }}>
        <NavButton
          type="button"
          onClick={() => setMethod('card')}
          active={method === 'card'}
        >
          Credit Card
        </NavButton>
        <NavButton
          type="button"
          onClick={() => setMethod('check')}
          active={method === 'check'}
        >
          {' '}
          Check
        </NavButton>
        <NavButton
          type="button"
          onClick={() => setMethod('wire')}
          active={method === 'wire'}
        >
          {' '}
          Wire Transfer
        </NavButton>
      </nav>
      {method === 'card' && (
        <CardPaymentForm
          cohort={props.cohort}
          enrollmentId={props.enrollmentId}
          payments={props.payments}
        />
      )}
      {method === 'check' && <CheckPaymentInfo cohort={props.cohort} />}
      {method === 'wire' && <WirePaymentInfo cohort={props.cohort} />}
    </>
  );
}

const NavButton = styled(UnstyledButton)`
  font-size: 0.875rem;
  line-height: 2.375rem;
  font-weight: 500;
  padding: 0 0.5rem;
  flex-grow: 1;
  border-bottom: 0.25rem solid white;
  ${({ active, theme }) =>
    active
      ? `
  color: ${theme.colors.blue};
  border-bottom: 0.25rem solid ${theme.colors.blue};
  `
      : `
  color: ${theme.colors.midgray};`}
`;

const StyledH2 = styled.h2`
  font-weight: bold;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.darkestgray};
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
`;
