import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER } from '../Graphql/queries';
import StyledButton from '../Styles/StyledButton';
import TelephoneCirle from '../img/TelephoneCircle';

export default function ContactButton(props) {
  const { error, data } = useQuery(GET_USER);
  let name =
    data && data.user ? `${data.user.first_name} ${data.user.last_name}` : '';
  if (error) console.dir(error);

  return (
    <ContactUsButton onClick={() => window.PodiumWebChat.open({ name })}>
      <TelephoneCirle style={{ width: '1.25rem', marginRight: '0.5rem' }} />
      <span>Contact Us</span>
    </ContactUsButton>
  );
}

const ContactUsButton = styled(StyledButton)`
  width: 9.1875rem;
  display: flex;
  border: 0.09375rem solid ${({ theme }) => theme.colors.lightblue};
  color: ${({ theme }) => theme.colors.lightblue};
  font-weight: bold;
  padding: 0.9375rem;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;
  line-height: 0.9375rem;
  font-size: 0.9375rem;
  letter-spacing: 0.021875rem;

  &:active {
    background: ${({ theme }) => theme.colors.lightblue};
    color: white;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.lightblue};
    color: white;
  }
`;
