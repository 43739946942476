import React from 'react';
import Card from '../Shared/Card';
import TodoItem from '../Shared/TodoItem';
import Loading from '../Shared/Loading';
import { useCurrentApplication } from '../utils/hooks';
import _get from 'lodash/get';

export default function OnboardingTodoList({ remote }) {
  const { data, loading } = useCurrentApplication();

  const tasks = [
    {
      to: 'student-agreement',
      description: 'Sign student agreement',
      complete: !!_get(data, 'application.enrollment.signedStudentAgreement'),
    },
    {
      to: 'join-discord',
      description: 'Join Discord',
      complete: !!_get(data, 'application.enrollment.joinedDiscord'),
    },
  ];
  return (
    <Card
      span={2 / 3}
      variant="todo"
      header="to do - onboarding"
      defaultExpanded
    >
      {loading && <Loading />}
      {data &&
        data.user &&
        data.application &&
        tasks
          .sort((a, b) => {
            if (a.complete && b.complete) {
              return 0;
            }
            if (a.complete && !b.complete) {
              return 1;
            }
            if (!a.complete && b.complete) {
              return -1;
            }
            if (!a.complete && !b.complete) {
              return 0;
            }
            return 0;
          })
          .map(task => (
            <TodoItem
              key={task.description}
              to={task.to}
              complete={task.complete}
            >
              {task.description}
            </TodoItem>
          ))}
    </Card>
  );
}
