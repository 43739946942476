import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DownArrow from '../img/DownArrow';
import UpArrow from '../img/UpArrow';
import { UnstyledButton } from '../Styles/StyledButton';
import MenuIcon from '../img/ThreeDots';

export default function Card(props) {
  const Menu = props.menu;
  const Icon = props.icon;
  const [expanded, setExpanded] = useState(
    !props.collapsable || props.defaultExpanded || false,
  );
  const [showMenu, setShowMenu] = useState(false);

  return (
    <StyledSection
      id={props.id}
      style={props.style}
      className={props.className}
      span={props.span}
    >
      <StyledHeading variant={props.variant} collapsable={props.collapsable}>
        <StyledH2>
          {!!props.icon && (
            <IconSpan>
              <Icon />
            </IconSpan>
          )}{' '}
          {props.header}
        </StyledH2>
        <StyledDiv>
          {props.collapsable && (
            <IconButton
              data-testid="expand-card"
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              {expanded ? <UpArrow /> : <DownArrow />}
            </IconButton>
          )}
          {!!props.menu && (
            <IconButton onClick={() => setShowMenu(!showMenu)}>
              <MenuIcon />
            </IconButton>
          )}
          {showMenu && (
            <div
              style={{ position: 'relative' }}
              onClick={() => setShowMenu(!showMenu)}
            >
              <Menu />
            </div>
          )}
        </StyledDiv>
      </StyledHeading>
      {expanded && (
        <ContentDiv variant={props.variant}>{props.children}</ContentDiv>
      )}
    </StyledSection>
  );
}

const ContentDiv = styled.div`
  /* padding: 1.375rem 1.5rem; */
  padding: 0;
  font-size: 0.875rem;
`;

const IconSpan = styled.span`
  margin: -0.625rem 0.375rem -0.625rem -0.5rem;
  margin-left: -0.5rem;
  margin-right: 0.375rem;
  color: currentColor;
`;

const StyledSection = styled.section`
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.1);
  margin: 1rem 0 2.5rem 0;
  color: ${({ theme }) => theme.colors.darkestgray};
  @media(min-width: 1024px){
    width: ${({ span }) => {
      const c = 936 * span - 40 * (1 - span);
      const result = (c / 936) * 100;
      return `${result}%`;
    }}
    margin-right: 3.9vw;
    &:last-child{
      margin-right: 0;
    }
  }
`;

const StyledH2 = styled.h2`
  font-size: 0.9375rem;
  /* font-family: 'Libre Franklin'; */
  font-weight: 600;
  padding: 0 0.75rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: currentColor;
  letter-spacing: 0.008125rem;
`;

const StyledHeading = styled.header`
  border-bottom: 0.0625rem solid
    ${({ theme, collapsable }) =>
      collapsable ? theme.colors.lightestgray : 'white'};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 1.5rem 0.75rem;
  /* padding: 0.875rem 0.75rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ variant, theme }) => {
    switch (variant) {
      case 'todo':
        return `
    background: ${theme.colors.lightblue};
    color: white;
    border-color: unset;
    padding-left: 1.75rem;
  `;
      case 'normal':
        return `
          background: white;
          color: black;
        `;
      default:
        return null;
    }
  }}
  &:last-child {
    border: none;
    border-radius: 3px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
`;

const IconButton = styled(UnstyledButton)`
  display: flex;
  flex-direction: column;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  &:not(:first-of-type) {
    margin-left: 0.25rem;
  }
  &:not(:last-of-type) {
    margin-right: 0.25rem;
  }
  color: currentColor;
  outline: none;
`;

Card.propTypes = {
  defaultExpanded: PropTypes.bool,
  header: PropTypes.any,
  menu: PropTypes.node,
  collapsable: PropTypes.bool,
  span: PropTypes.number,
};

Card.defaultProps = {
  defaultExpanded: false,
  header: '',
  menu: null,
  collapsable: true,
  span: 1,
};
