import React, { useState, useRef } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  injectStripe,
} from 'react-stripe-elements';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import '../Styles/stripe.css';
import DepositModal from './DepositModal';
import { PaymentButton } from './Payments';
import PaymentStatusModal from './PaymentStatusModal';
import Input, { SpacingSpan, ErrorSpan } from '../Shared/Input';
import ProcessingAnimation from '../Shared/ProcessingAnimation';
import { useCurrentApplication } from '../utils/hooks';
import { calcDepositOutstanding } from '../utils/financeCenterUtils';
import _get from 'lodash/get';

const stripeStyle = {
  base: {
    color: 'rgba(0, 48, 90, 1)',
    fontFamily: 'Raleway',
    // The stripe iframe sets
    // its default font-size at 14px
    // so this 3rem is based on that
    // instead of 16px
    // lineHeight: '3rem',
    padding: '2rem',
  },
  invalid: {
    color: 'rgba(0, 48, 90, 1)',
  },
};

const MAKE_PAYMENT = gql`
  mutation MakePayment($payment: StripeInput!) {
    makePayment(payment: $payment) {
      id
      date
      category
      amount
    }
  }
`;

ReactModal.setAppElement('#root');

function CardPaymentForm(props) {
  const application = useCurrentApplication();
  const isRemote = _get(application, 'cohort.data.isRemote');
  const strfAmount = _get(
    application,
    'data.application.enrollment.payments.strf.amount',
  );
  const strfComplete = _get(
    application,
    'data.application.enrollment.payments.strf.complete',
  );

  const shouldRequireStrf =
    typeof strfAmount === 'number' && strfComplete === false;

  const [mutate, { data, loading, error }] = useMutation(MAKE_PAYMENT, {
    refetchQueries: ['GetApplication'],
    awaitRefetchQueries: true,
  });
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [stripeError, setStripeError] = useState('');
  const [numberError, setNumberError] = useState('');
  const [expiryError, setExpiryError] = useState('');
  const [cvcError, setCvcError] = useState('');
  const [processing, setProcessing] = useState(false);
  const numberRef = useRef(null);
  const expiryRef = useRef(null);
  const cvcRef = useRef(null);

  let paymentError;
  if (!error) {
    paymentError = stripeError;
  } else if (
    !error.graphQLErrors ||
    !error.graphQLErrors[0].message ||
    !(
      _get(error, 'graphQLErrors[0].extensions.exception.type') ===
      'StripeCardError'
    )
  ) {
    paymentError = 'Something went wrong.';
  } else {
    console.dir(error);
    paymentError = error.graphQLErrors[0].message;
  }

  const validate = (values = {}) => {
    const result = {};

    if (!values.amount || values.amount < 1) {
      result.amount = 'Enter a payment amount greater than $1.00.';
    }
    if (!values.cardholderName) {
      result.cardholderName = "Enter the cardholder's name.";
    }

    try {
      // this is in a try catch just in case the data somehow
      // isn't in application.data yet.
      if (
        +values.amount >
        _get(
          application,
          'data.application.enrollment.payments.tuition.outstanding',
          0,
        )
      ) {
        result.amount =
          'Your payment amount exceeds the amount owed. Enter a different amount.';
      }

      if (shouldRequireStrf && +values.amount < strfAmount) {
        result.amount =
          'Your payment amount must be greater than the required STRF fee.';
      }
    } catch (e) {
      console.error(e);
    }

    return result;
  };

  return (
    <Formik
      initialValues={{
        amount: '',
        cardholderName: '',
        zipcode: '',
      }}
      validate={validate}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setProcessing(true);
        try {
          const result = await props.stripe.createToken({
            name: values.cardholderName,
            address_zip: values.zipcode,
          });
          if (result.error) {
            return setStripeError(result.error.message);
          }

          await mutate({
            variables: {
              payment: {
                amount: Math.ceil(+values.amount * 100),
                tokenId: result.token.id,
                enrollmentId: props.enrollmentId,
                category: 'Tuition',
                program: props.cohort.programName,
                location: isRemote ? 'Remote' : props.cohort.Location_City__c,
                startDate: props.cohort.Date_Start__c,
                endDate: props.cohort.Date_End__c,
                description: `Tuition, ${props.cohort.programName} ${
                  props.cohort.Type__c
                } (${isRemote ? 'Remote' : props.cohort.Location_City__c} ${
                  props.cohort.Date_Start__c
                })`,
                strfAmount: shouldRequireStrf ? strfAmount * 100 : null,
              },
            },
          });

          setStripeError('');
          setShowStatusModal(true);
          resetForm();
          numberRef.current.clear();
          expiryRef.current.clear();
          cvcRef.current.clear();
        } catch (err) {
          setStripeError(err.message);
        } finally {
          setProcessing(false);
          setSubmitting(false);
        }
      }}
    >
      {formik => (
        <Form style={{ paddingTop: '1.5rem' }}>
          <DepositModal
            showModal={showDepositModal}
            setShowModal={setShowDepositModal}
            submitForm={formik.submitForm}
          />
          <PaymentStatusModal
            setShowModal={setShowStatusModal}
            isOpen={showStatusModal}
            details={_get(data, 'makePayment[0]')}
          />
          <ModifiedInput
            name="amount"
            onChange={e => {
              const digits = +e.target.value.replace(/[^0-9]/gi, '') / 100;
              formik.setFieldValue('amount', digits);
            }}
            value={formik.values.amount.toLocaleString(undefined, {
              style: 'currency',
              currency: 'usd',
              minimumFractionDigits: 2,
              currencyDisplay: 'symbol',
            })}
            placeholder="Amount"
            inputMode="decimal"
          />

          <ModifiedInput name="cardholderName" placeholder="Cardholder Name" />

          <div>
            {!loading && paymentError && <span id="error" />}
            <CardNumberElement
              style={stripeStyle}
              className="card-number"
              onReady={el => {
                numberRef.current = el;
              }}
              onChange={({ error }) => {
                if (error) {
                  setNumberError(error.message);
                } else if (numberError) {
                  setNumberError('');
                }
                if (stripeError) {
                  setStripeError('');
                }
              }}
            />
            <ErrorSpan>
              {!loading &&
                (numberError || (!expiryError && !cvcError && paymentError))}
              <SpacingSpan>*</SpacingSpan>
            </ErrorSpan>
            <div className="card-data-container">
              <CardExpiryElement
                style={stripeStyle}
                className="card-data"
                onReady={el => {
                  expiryRef.current = el;
                }}
                onChange={({ error }) => {
                  if (error) {
                    setExpiryError(error.message);
                  } else if (expiryError) {
                    setExpiryError('');
                  }
                  if (stripeError) {
                    setStripeError('');
                  }
                }}
              />
              <CardCvcElement
                style={stripeStyle}
                className="card-data"
                onReady={el => {
                  cvcRef.current = el;
                }}
                onChange={({ error }) => {
                  if (error) {
                    setCvcError(error.message);
                  } else if (cvcError) {
                    setCvcError('');
                  }
                  if (stripeError) {
                    setStripeError('');
                  }
                }}
              />
              <div className="card-data">
                <ModifiedInput
                  inputMode="numeric"
                  hideErrors
                  name="zipcode"
                  placeholder="Zip"
                />
              </div>
            </div>
            <ErrorSpan as="div">
              {expiryError || cvcError}
              <SpacingSpan style={{ margin: 0 }}>*</SpacingSpan>
            </ErrorSpan>
            <ErrorSpan as="div">
              {expiryError && cvcError}
              <SpacingSpan>*</SpacingSpan>
            </ErrorSpan>
          </div>

          <PaymentButton
            variant={loading || formik.isSubmitting ? 'disabled' : 'primary'}
            type={
              _get(
                application,
                'data.application.enrollment.payments.deposit.complete',
              )
                ? 'submit'
                : 'button'
            }
            onClick={e => {
              if (
                !_get(
                  application,
                  'data.application.enrollment.payments.deposit.complete',
                )
              ) {
                e.preventDefault();
                setShowDepositModal(true);
              }
            }}
            disabled={loading || formik.isSubmitting}
          >
            <div>{processing ? <ProcessingAnimation /> : 'Submit'}</div>
          </PaymentButton>
        </Form>
      )}
    </Formik>
  );
}

export default injectStripe(CardPaymentForm);

const ModifiedInput = styled(Input)`
  font-size: 0.875rem;
  padding: 0.75rem 1rem;
  height: unset;
`;
