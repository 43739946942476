import React from 'react';
import Loading from './Loading';
import { useSalesforceCopy } from '../utils/hooks';


export default function SalesforceCopy({ field, fallback = null, component: Component = "div" }) {
    const [{loading}, {innerHTML, hasContent}] = useSalesforceCopy(field);

    return (
        <>
            {loading && <Loading />}
            {hasContent && <Component dangerouslySetInnerHTML={innerHTML} />}
            {!loading && !hasContent && fallback}
        </>
    );
}
