import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, Match } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';

import logoNoText from '../img/logo-no-text.png';
import { GET_USER } from '../Graphql/queries';
import DownArrow from '../img/DownArrow';
import HamburgerMenu from './HamburgerMenu';
import ThreeDots from '../img/ThreeDots';
import { UnstyledButton } from '../Styles/StyledButton';

export default function Header(props) {
  const { data } = useQuery(GET_USER);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <StyledHeader headerColor="blue">
      <Link to={`/dashboard`}>
        <StyledImg
          src={logoNoText}
          alt="Devmountain Logo"
          padding="1rem 0 1rem 1rem"
          maxHeight="2.5rem"
        />
      </Link>
      <Match path="*">
        {({ location }) => {
          const isApplication = location.pathname.includes('apply');
          return (
            <StyledDiv>
              <div style={{ position: 'relative' }}>
                {get(data, 'user') && (
                  <UnstyledButton
                    type="button"
                    style={{ padding: '0 0.5rem', color: 'white' }}
                    onClick={() => setShowMenu(!showMenu)}
                    className="ignore-react-onclickoutside"
                  >
                    <NameContainer>
                      {!isApplication && get(data, 'user.first_name')}
                    </NameContainer>

                    {isApplication ? <ThreeDots /> : <DownArrow />}
                  </UnstyledButton>
                )}
                {showMenu && (
                  <HamburgerMenu
                    setShowMenu={setShowMenu}
                    isApplication={isApplication}
                  />
                )}
              </div>
            </StyledDiv>
          );
        }}
      </Match>
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  background: ${({ theme, headerColor }) => theme.colors[headerColor]};
  color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  height: 4.375rem;
  font-family: ${({ theme }) => theme.fonts.rale};
  font-size: 0.875rem;
  font-weight: 600;
  @media (min-width: 1024px) {
    border-bottom: none;
  }
`;

const StyledImg = styled.img`
  box-sizing: content-box;
  padding: ${({ padding }) => padding};
  max-height: ${({ maxHeight }) => maxHeight};
`;

const StyledDiv = styled.div`
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  padding: 1.5rem 1rem 1rem 0;
`;

const NameContainer = styled.span`
  font-weight: 600;
  padding-right: 0.5rem;
  font-size: 0.875rem;
  line-height: 1rem;
`;
