import moment from 'moment';
import * as Yup from 'yup';
import _get from 'lodash/get';
import * as Sentry from '@sentry/browser';
import { UTM_PARAMS_KEY } from '../constants';

const tShirtSizes = ['xs', 's', 'm', 'l', 'xl', 'xxl'];

function genRandPrefix() {
  let prefix = '';
  while (prefix.length < 3) {
    prefix = prefix += String.fromCharCode(
      Math.floor(Math.random() * (122 - 97) + 97),
    );
  }
  return prefix;
}

export function addComma(str) {
  if (typeof str !== 'string') return '';
  return str.length ? `${str}, ` : '';
}

export function genRandId(min, max, prefix = genRandPrefix()) {
  min = Math.ceil(min);
  max = Math.floor(max);
  const id = `${prefix}-${Math.floor(Math.random() * (max - min) + min)}`;
  return id;
}

export function splitQueryString(string = '') {
  const queryVals = {};
  const split = string.substr(1).split('&');
  for (let i = 0; i < split.length; i++) {
    const pair = split[i].split('=');
    queryVals[pair[0]] = pair[1];
  }
  return queryVals;
}

export const showErrors = (section, formik) => {
  const result =
    Object.keys(formik.touched || {}).reduce(
      (acc, key) => acc || (formik.touched[key] && formik.errors[key] && true),
      false,
    ) || false;

  return result;
};

const validationSchema = Yup.object().shape({
  section: Yup.string(),
  contact: Yup.object().when('section', {
    is: section => section === 'contact' || section === 'all',
    then: Yup.object().shape({
      applicantType: Yup.string()
        .oneOf(['us', 'international'])
        .required('Choose US or International'),
      mailingAddress1: Yup.string().required('Enter a valid mailing address'),
      city: Yup.string().required('Enter a valid city'),
      region: Yup.string().required('Enter a valid state or region'),
      postalCode: Yup.string().required('Enter a valid zip or postal code'),
      phone: Yup.string()
        .matches(
          /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
          'Enter a valid phone number',
        )
        .required('Enter a valid phone number'),
      birthday: Yup.string()
        // .matches(
        //   /^((0|1)\d{1})\/((0|1|2)\d{1})\/((19|20)\d{2})/,
        //   'Enter a valid birthday in mm/dd/yyyy format',
        // )
        .required('Enter a valid birthday in mm/dd/yyyy format'),
      gender: Yup.string().required('Enter a valid gender'),
      housing: Yup.string().oneOf(
        ['Not interested', 'Interested in housing', 'Must have housing', ''],
        'Choose your interest in housing.',
      ),
      size: Yup.string().oneOf(tShirtSizes).required('Enter a valid t-shirt size.')
    }),
    otherwise: Yup.object(),
  }),
  education: Yup.object().when('section', {
    is: section => section === 'education' || section === 'all',
    then: Yup.object().shape({
      highestEducation: Yup.string().oneOf(
        [
          'High School',
          'Some College',
          'Associates',
          'Bachelors',
          'Masters',
          'PHD',
        ],
        'Select a valid level of education',
      ),
      experience: Yup.object().shape({
        htmlCss: Yup.bool().required(),
        javascript: Yup.bool().required(),
        github: Yup.bool().required(),
        react: Yup.bool().required(),
        sql: Yup.bool().required(),
        node: Yup.bool().required(),
        computerScience: Yup.bool().required(),
      }),
      hoursSpent: Yup.string()
        .matches(/^[0-9]+$/, 'Enter a valid number of hours.')
        .required('Enter a valid number of hours.'),
    }),
    otherwise: Yup.object(),
  }),
  goals: Yup.object().when('section', {
    is: section => section === 'goals' || section === 'all',
    then: Yup.object().shape({
      goals: Yup.string().required('Enter a valid response.'),
      hearAbout: Yup.object().shape({
        google: Yup.bool(),
        googleDetail: Yup.string().when('google', {
          is: true,
          then: Yup.string()
            .trim()
            .required('Enter a response.'),
          otherwise: Yup.string(),
        }),
        googleBusiness: Yup.bool(),
        bingYahoo: Yup.bool(),
        bingYahooDetail: Yup.string().when('bingYahoo', {
          is: true,
          then: Yup.string()
            .trim()
            .required('Enter a response.'),
          otherwise: Yup.string(),
        }),
        reddit: Yup.bool(),
        discord: Yup.bool(),
        quora: Yup.bool(),
        advertisement: Yup.bool(),
        internet: Yup.bool(),
        internetDetail: Yup.string().when('internet', {
          is: true,
          then: Yup.string()
            .trim()
            .required('Enter a response.'),
          otherwise: Yup.string(),
        }),
        article: Yup.bool(),
        alumni: Yup.bool(),
        instructor: Yup.bool(),
        currentStudent: Yup.bool(),
        work: Yup.bool(),
        codingBasics: Yup.bool(),
        careerKarma: Yup.bool(),
        reviewsSite: Yup.bool(),
        campus: Yup.bool(),
        strayerCapella: Yup.bool(),
        traversyMedia: Yup.bool(),
        facebook: Yup.bool(),
        instagram: Yup.bool(),
        youtube: Yup.bool(),
        twitter: Yup.bool(),
        linkedin: Yup.bool(),
        friendOrRelative: Yup.bool(),
        courseReport: Yup.bool(),
        other: Yup.bool(),
        otherDetail: Yup.string().when('other', {
          is: true,
          then: Yup.string()
            .trim()
            .required('Enter a response.'),
          otherwise: Yup.string(),
        }),
      }),
      acknowledgement: Yup.bool().oneOf(
        [true],
        'Read and accept the acknowledgement',
      ),
    }),
    otherwise: Yup.object(),
  }),
});

function setInitialValues(data, section) {
  const application = _get(data, 'application') || {
    experience: {},
    hearAbout: {},
    workExperience: [],
  };
  const experience = _get(application, 'experience') || {};
  const hearAbout = _get(application, 'hearAbout') || {};
  return {
    navigate: true,
    section,
    contact: {
      applicantType: application.applicantType || 'us',
      mailingAddress1: application.mailingAddress1 || '',
      city: application.city || '',
      region: application.region || '',
      postalCode: application.postalCode || '',
      country: application.country || 'US',
      phone: application.phone || '',
      birthday: application.birthday
        ? moment(application.birthday, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : '',
      gender: application.gender || '',
      housing: application.housing || '',
      size: application.size || '',
    },
    education: {
      highestEducation: application.highestEducation || '',
      fieldOfStudy: application.fieldOfStudy || '',
      experience: {
        htmlCss: experience.htmlCss || false,
        javascript: experience.javascript || false,
        github: experience.github || false,
        react: experience.react || false,
        sql: experience.sql || false,
        node: experience.node || false,
        computerScience: experience.computerScience || false,
      },
      hoursSpent: application.hoursSpent || '',
      hoursSpentType: application.hoursSpentType || '',
    },
    experience: {
      workExperience: application.workExperience || [
        { position: '', length: '', description: '' },
      ],
    },
    goals: {
      goals: application.goals || '',
      hearAbout: {
        google: hearAbout.google || false,
        googleDetail: hearAbout.googleDetail || '',
        googleBusiness: hearAbout.googleBusiness || false,
        bingYahoo: hearAbout.bingYahoo || false,
        bingYahooDetail: hearAbout.bingYahooDetail || '',
        reddit: hearAbout.reddit || false,
        discord: hearAbout.discord || false,
        quora: hearAbout.quora || false,
        advertisement: hearAbout.advertisement || false,
        internet: hearAbout.internet || false,
        internetDetail: hearAbout.internetDetail || '',
        article: hearAbout.article || false,
        alumni: hearAbout.alumni || false,
        instructor: hearAbout.instructor || false,
        currentStudent: hearAbout.currentStudent || false,
        work: hearAbout.work || false,
        codingBasics: hearAbout.codingBasics || false,
        careerKarma: hearAbout.careerKarma || false,
        reviewsSite: hearAbout.reviewsSite || false,
        campus: hearAbout.campus || false,
        strayerCapella: hearAbout.strayerCapella || false,
        traversyMedia: hearAbout.traversyMedia || false,
        facebook: hearAbout.facebook || false,
        instagram: hearAbout.instagram || false,
        youtube: hearAbout.youtube || false,
        twitter: hearAbout.twitter || false,
        linkedin: hearAbout.linkedin || false,
        friendOrRelative: hearAbout.friendOrRelative || false,
        courseReport: hearAbout.courseReport || false,
        other: hearAbout.other || false,
        otherDetail: hearAbout.otherDetail || '',
      },
      acknowledgement: application.acknowledgement || false,
    },
  };
}

export async function calculateCompletion(data) {
  const values = setInitialValues(data, 'all');
  const allValid = await validationSchema.isValid(values);
  if (allValid) {
    return {
      contact: true,
      education: true,
      experience: true,
      goals: true,
    };
  }

  const contactPromise = validationSchema.isValid(
    setInitialValues(data, 'contact'),
  );
  const educationPromise = validationSchema.isValid(
    setInitialValues(data, 'education'),
  );
  const experiencePromise =
    data &&
    data.application &&
    data.application.workExperience &&
    validationSchema.isValid(setInitialValues(data, 'experience'));
  const goalsPromise = validationSchema.isValid(
    setInitialValues(data, 'goals'),
  );

  const [contact, education, experience, goals] = await Promise.all([
    contactPromise,
    educationPromise,
    experiencePromise,
    goalsPromise,
  ]);

  return {
    contact,
    education,
    experience,
    goals,
  };
}

export const submittedApplicationTracking = (location = '', subject) => {
  if (process.env.NODE_ENV !== 'development') {
    if (window.hasOwnProperty('fbq')) {
      window.fbq('trackCustom', 'CompleteRegistration', {
        value: 25.0,
        currency: 'USD',
      });
    }
    if (window.hasOwnProperty('gtag')) {
      window.gtag('config', 'UA-41999703-1', {
        page_title: 'student_application',
        page_path: `/sfportal?application_received=1&type=full&subject=${subject}&location=${location}&newportal=true`,
      });
    }
    try {
      if (typeof window.rdt === 'function') {
        window.rdt('track', 'SignUp');
      }
    } catch (err) {
      console.error('Error sending event', err);
    }
  } else console.log(`Simulating application tracking: Location = ${location}`);
};

// get UTM params from the URL or localStorage if they exist
// (these are set in localStorage in app.js)
// utm object is nullable but not the individual params
export const getUtmParams = () => {
  const paramsFromUrl = getUtmParamsFromURL();
  const paramsFromLocalStorage = getUtmParamsFromLocalStorage();

  return paramsFromUrl || paramsFromLocalStorage;
};

const getUtmParamsFromLocalStorage = () => {
  try {
    return JSON.parse(localStorage.getItem(UTM_PARAMS_KEY) || 'null');
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
};

export const getUtmParamsFromURL = () => {
  const params = new URLSearchParams(window.location.search);

  const source = params.get('utm_source') || '';
  const medium = params.get('utm_medium') || '';
  const campaign = params.get('utm_campaign') || params.get('campaign') || '';
  const content = params.get('utm_content') || '';
  const paidVsOrganic = params.get('paid_vs_organic') || '';
  const adType = params.get('ad_type') || '';
  const organicType = params.get('organic_type') || '';

  if (
    source ||
    medium ||
    campaign ||
    content ||
    paidVsOrganic ||
    adType ||
    organicType
  ) {
    return {
      source,
      medium,
      campaign,
      content,
      paidVsOrganic,
      adType,
      organicType,
    };
  }
  return null;
};
