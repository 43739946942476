import React, { useContext } from 'react';
import StyledSection from '../Styles/StyledSection';
import DashboardNavigation from '../Shared/DashboardNavigation';
import AgreementWidget from './AgreementWidget';
import StudentDirectoryWidget from './StudentDirectoryWidget';
import SlackSignUpWidget from './SlackSignUpWidget';
import Discord from './Discord';
import OnboardingTodoList from './OnboardingToDoList';
import { useCurrentApplication, useSalesforceCopy } from '../utils/hooks';
import { Row } from '../FinanceCenter/FinanceCenter';
import { MediaQueryContext } from '../Shared/MediaQueryProvider';
import { useApplicationId } from '../Shared/ApplicationProvider';
import _get from 'lodash/get';

export default function Onboarding() {
  const { data } = useCurrentApplication();

  const id = useApplicationId();
  const enrollmentId = _get(data, 'application.enrollment.id', '');
  const isDesktop = useContext(MediaQueryContext);
  // todo: update schema and queries to retrieve discordLink
  const discordLink = _get(data, 'application.enrollment.cohort.discordLink');
  const [, {hasContent}] = useSalesforceCopy('discordInstructions')
  return (
    <StyledSection variant="dashboard">
      <DashboardNavigation section="Onboarding" />
      <Row style={{ position: 'relative' }}>
        <OnboardingTodoList applicationId={id} />
        {isDesktop && <StudentDirectoryWidget applicationId={id} />}
      </Row>
      <Row>
        <AgreementWidget id={_get(data, 'application.enrollment.id', '')} />
      </Row>
      <Row>
        <SlackSignUpWidget applicationId={id} />
        {hasContent && <Discord enrollmentId={enrollmentId} applicationId={id} url={discordLink} />}
        {!isDesktop && <StudentDirectoryWidget applicationId={id} />}
      </Row>
    </StyledSection>
  );
}
