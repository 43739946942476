import React from 'react';

export default function CheckCardIcon(props) {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Group 9</title>
      <desc>Created with Sketch.</desc>
      <g
        id="svgs-jonathan"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CheckCardIcon-Artboard"
          transform="translate(-222.000000, -87.000000)"
        >
          <g
            id="CheckCardIcon-Group-9"
            transform="translate(222.000000, 87.000000)"
          >
            <g id="CheckCardIcon-icon" fill="#0EB6FE">
              <g id="CheckCardIcon-Group-4">
                <circle id="CheckCardIcon-Oval-16" cx="20" cy="20" r="20" />
              </g>
            </g>
            <g
              id="CheckCardIcon-check-list"
              transform="translate(8.000000, 8.000000)"
            >
              <g id="CheckCardIcon-Frame_-_24px">
                <rect
                  id="CheckCardIcon-Rectangle"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                />
              </g>
              <g
                id="CheckCardIcon-Line_Icons"
                transform="translate(0.000000, 2.000000)"
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <g id="CheckCardIcon-Group">
                  <path
                    d="M22.042,20 L2.042,20 C0.939,20 0.042,19.103 0.042,18 L0.042,2 C0.042,0.898 0.939,0 2.042,0 L22.042,0 C23.145,0 24.042,0.898 24.042,2 L24.042,18 C24.042,19.103 23.145,20 22.042,20 Z M2.042,2 L2.042,18 L22.043,18 L22.042,2 L2.042,2 Z"
                    id="CheckCardIcon-Shape"
                  />
                  <polygon
                    id="CheckCardIcon-Path"
                    points="7.462 14.784 3.348 12.041 4.735 9.96 6.621 11.217 9.956 5.38 12.127 6.621"
                  />
                  <rect
                    id="CheckCardIcon-Rectangle"
                    x="14.042"
                    y="7"
                    width="6"
                    height="2"
                  />
                  <rect
                    id="CheckCardIcon-Rectangle"
                    x="14.042"
                    y="11"
                    width="6"
                    height="2"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
