import React from 'react';
import ProgressItem from './ProgressItem';
import sectionIcons from '../../img/sectionIcons';
import moment from 'moment';
import _get from 'lodash/get';
import RequiredIcon from '../../Styles/RequiredIcon';
import { useCurrentApplication } from '../../utils/hooks';

export default function PreCourseProgress(props) {
  const { data } = useCurrentApplication();
  const application = _get(data, 'application') || {};
  const enrollment = application.enrollment || {};

  return (
    <ProgressItem
      to="pre-course-work"
      action={<RequiredIcon />}
      icon={sectionIcons['Pre-Course Work']}
      unlocked={!!(application.viewedDecision && application.enrollment)}
      description="Pre-Course Work"
      complete={
        enrollment.precourseCompleted
          ? moment(enrollment.precourseCompleted).format('MMMM DD, YYYY')
          : ''
      }
    />
  );
}
