import React from 'react';
import { captureException } from '@sentry/browser';
import ProgressItem from './ProgressItem';
import sectionIcons from '../../img/sectionIcons';
import { useCurrentApplication } from '../../utils/hooks';
import _get from 'lodash/get';
import StyledButton from '../../Styles/StyledButton';

export default function ScholarshipApplicationProgress(props) {
  const { data, error } = useCurrentApplication();
  if (error) {
    console.error(error);
    captureException(error);
  }
  const scholarship = _get(data, 'application.cohort.scholarship');
  const personalInfoComplete = _get(data, 'application.complete.personalInfo') || false;

  if (!scholarship || !personalInfoComplete) {
    return null;
  }

  const { applied, decided } =
    _get(data, 'application.scholarshipApplication') || {};
  let progress = 0;
  const items = 2;
  if (applied) {
    progress += 1;
  }
  if (decided) {
    progress += 1;
  }

  let buttonText;

  if (decided){
    buttonText = 'View Decision';
  } else if (applied){
    buttonText = "Under Review";
  } else {
    buttonText = "Begin"
  }

  return (
    <ProgressItem
      to="scholarship"
      action={
        <StyledButton variant="secondary" rightIcon>
          {buttonText}
        </StyledButton>
      }
      showActionOnComplete
      icon={sectionIcons['Scholarship Application']}
      unlocked
      description="Scholarship Application"
      progress={Math.ceil((progress / items) * 100)}
      complete={progress === items}
    />
  );
}
