import React, { useState } from 'react';
import { Elements } from 'react-stripe-elements';
import { Redirect } from '@reach/router';
import styled from 'styled-components';
import Card from '../Shared/Card';
import Loading from '../Shared/Loading';

import DollarIcon from '../img/DollarIcon';
import PaymentHistory from './PaymentHistory';
import CardSection from '../Styles/CardSection';
import { UnstyledButton } from '../Styles/StyledButton';
import PaymentDueMessage from './PaymentDueMessage';
import PaymentOptions from './PaymentOptions';
import { useCurrentApplication } from '../utils/hooks';
import _get from 'lodash/get';

export default function Payments(props) {
  const { data, loading, error } = useCurrentApplication();
  const [showForm, setShowForm] = useState(false);

  if (loading && !_get(data, 'application.enrollment')) return <Loading />;
  if (error) {
    console.dir(error);
    return 'Error';
  }
  if (!loading && !_get(data, 'application.enrollment')) {
    console.dir('No enrollment found for application');
    return <Redirect to={`/dashboard`} />;
  }
  return (
    <Card
      span={1 / 2}
      header="Payments"
      defaultExpanded
      id="payments"
      icon={DollarIcon}
    >
      {' '}
      <CardSection style={{ padding: '1rem' }}>
        <PaymentDueMessage
          payments={_get(data, 'application.enrollment.payments')}
        />

        <Elements
          fonts={[
            {
              family: 'Raleway',
              cssSrc:
                'https://fonts.googleapis.com/css?family=Raleway:400,500,600,700',
            },
          ]}
        >
          {showForm ? (
            <>
              <StyledHr />
              <PaymentOptions
                cohort={data.application.enrollment.cohort}
                enrollmentId={data.application.enrollment.id}
                payments={_get(data, 'application.enrollment.payments')}
              />
            </>
          ) : (
            <PaymentButton onClick={() => setShowForm(true)}>
              Make a Payment
            </PaymentButton>
          )}
        </Elements>

        <PaymentHistory id={props.id} />

        <StyledP>
          For questions regarding your balance or recent payments contact{' '}
          <StyledA href="mailto:financing@devmountain.com">
            financing@devmountain.com
          </StyledA>
        </StyledP>
      </CardSection>
    </Card>
  );
}

const StyledP = styled.p`
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin-top: 1rem;
`;

const StyledA = styled.a`
  color: ${({ theme }) => theme.colors.lightblue};
  font-weight: 600;
  text-decoration: none;
`;

export const PaymentButton = styled(UnstyledButton)`
  background: rgba(0, 69, 129, 1);
  color: white;
  width: 100%;
  padding: 0.75rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1rem;
  border-radius: 0.1875rem;

  &:active {
    background: ${({ theme }) => theme.colors.darkblue};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.darkblue};
  }

  :disabled {
    cursor: not-allowed;
    background-color: rgb(135, 135, 135);
  }
`;

const StyledHr = styled.hr`
  border: 0;
  height: 0.0625rem;
  width: 100%;
  background: ${({ theme }) => theme.colors.lightgray};
`;
