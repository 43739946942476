import React from 'react';
import _get from 'lodash/get';
import { useCurrentApplication } from '../utils/hooks';
import ScholarshipApproved from './ScholarshipApproved';
import ScholarshipRejected from './ScholarshipRejected';
import ScholarshipPending from './ScholarshipPending';
import ScholarshipApplication from './ScholarshipApplication';
import Loading from '../Shared/Loading';

export default function Scholarship() {
  const { data, loading } = useCurrentApplication();
  const scholarshipApplication = _get(data, 'application.scholarshipApplication') || {};
  const { applied, decided, award } = scholarshipApplication;

  if (loading){
    return <Loading />
  }

  if (decided && award){
    return <ScholarshipApproved />
  }

  if (decided && !award){
    return <ScholarshipRejected />
  }

  if (applied){
    return <ScholarshipPending />
  }

  return <ScholarshipApplication />
  
}
