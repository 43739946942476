import React from 'react';
import ReactModal from 'react-modal';
import moment from 'moment';
import styled from 'styled-components';
import { modalStyle, ModalP, ModalX } from '../Styles/modalStyles';
import successCheckCircle from '../img/success-check-circle.svg';

export default function PaymentStatusModal(props) {
  const { isOpen, setShowModal, details } = props;
  return (
    <ReactModal
      onRequestClose={() => {
        setShowModal(false);
      }}
      style={modalStyle}
      isOpen={isOpen}
    >
      {details && (
        <>
          <ModalX
            onClick={() => {
              setShowModal(false);
            }}
          />
          <ModalP as="div">
            <StyledSection>
              <img src={successCheckCircle} alt="Payment Successful" />
              <StyledH1>Payment Successful</StyledH1>
            </StyledSection>
            <span>
              {/* Previously we included the payment amount, but 
              sometimes (for payments that include a STRF fee) we split
              a signle Stripe charge into separate Salesforce payments,
              which would cause the amount to appear incorrect. */}
              Thanks! Your payment has been credited to your account on{' '}
              <StyledEm>
                {moment(details.date).format('MMMM DD, YYYY')}
              </StyledEm>
              .
            </span>
          </ModalP>
        </>
      )}
    </ReactModal>
  );
}

const StyledSection = styled.section`
  text-align: center;
`;

const StyledH1 = styled.h1`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
  margin: 2rem 0 1rem;
`;

const StyledEm = styled.em`
  font-weight: bold;
`;
