import React, { useEffect } from 'react';
import LogRocket from 'logrocket';
import { Router, Redirect } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import _get from 'lodash/get';
import Header from './Shared/Header';
import Footer from './Shared/Footer';
import CreateAccount from './CreateAccount/CreateAccount';
import DashboardRouter from './Dashboard/DashboardRouter';
import Loading from './Shared/Loading';
import { GET_USER } from './Graphql/queries';
import ApplicationProvider from './Shared/ApplicationProvider';
import Account from './Account/Account';
import SnackbarProvider from './Shared/SnackbarProvider';
import { MediaQueryProvider } from './Shared/MediaQueryProvider';
import ErrorBoundary from './ErrorBoundary';
import ApplicationLanding from './Application/ApplicationLanding';
import ApplicationContent from './Application/ApplicationContent';
import SupportWidget from './Shared/SupportWidget';
import axios from 'axios';
import { useSetUtmParamsInLocalStorage } from './utils/hooks';

window.STUDENT_PORTAL_VERSION = '0.1.0';

function App() {
  const { loading, error, data } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
    returnPartialData: true,
  });

  const sfId = _get(data, 'user.sfId');

  useEffect(() => {
    if (sfId) {
      LogRocket.identify(sfId);
    }
  }, [sfId]);

  if (error) {
    console.error(error);
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('dc')) {
      axios.post('/api/discount-code', {
        dc: params.get('dc'),
      });
    }
  }, []);

  useSetUtmParamsInLocalStorage();

  return (
    // <React.StrictMode>
    <ExtraWideContainer>
      <MediaQueryProvider>
        <SnackbarProvider>
          <ApplicationProvider>
            <Header />
            <Router primary={false}>
              <SupportWidget default />
            </Router>
            <ErrorBoundary>
              <ContentContainer>
                {loading && (!data || !data.user) && <Loading />}
                {error && (
                  <ErrorRoute content="An unknown error occurred. Please try again." />
                )}
                {!loading && !error && (!data || !data.user) && (
                  <Router primary={false}>
                    <CreateAccount path="/" />
                    <Loading path="/loading" />
                    <Redirect
                      noThrow={true}
                      from="*"
                      to={'/' + window.location.search}
                      default
                    />
                  </Router>
                )}
                {data && data.user && (
                  <Router primary={false}>
                    <CreateAccount path="/" />
                    <Account path="/account" />
                    <Loading path="/loading" />
                    <ApplicationLanding path="/apply" />
                    <ApplicationContent path="/apply/*" />
                    <DashboardRouter path="/dashboard/*" />
                    <ErrorRoute default />
                  </Router>
                )}
              </ContentContainer>
            </ErrorBoundary>
            <Footer />
          </ApplicationProvider>
        </SnackbarProvider>
      </MediaQueryProvider>
    </ExtraWideContainer>
    // </React.StrictMode>
  );
}

export default App;

const ExtraWideContainer = styled.div`
  background: #ffffff;
  @media (min-width: 1024px) {
    margin: 0 auto;
    width: 1024px;
  }
`;

const ErrorRoute = styled.div`
  margin: 2rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ::before {
    content: ${({ content }) => content || '404 \n Not found'};
  }
`;

const ContentContainer = styled.main`
  /* 100vh - header - footer */
  min-height: calc(100vh - 70px - 70px);
  position: relative;
`;
