import React, { useContext, useEffect } from 'react';
import StyledSection from '../Styles/StyledSection';
import IntroMessage from './IntroMessage';
import CohortWidget from './CohortWidget';
import ActivityList from './ActivityList/ActivityList';
import { useCurrentApplication } from '../utils/hooks';
import { MediaQueryContext } from '../Shared/MediaQueryProvider';
import DashboardNavigation from '../Shared/DashboardNavigation';
import { Row } from '../FinanceCenter/FinanceCenter';
import _get from 'lodash/get';
import WithdrawnApplication from './WithdrawnApplication';
import Card from '../Shared/Card';
import { ReactComponent as YellowAlert } from '../img/YellowAlert.svg';
import StyledP from '../Styles/StyledP';
import { Dmlink } from '../Styles/StyledA';
import { captureException } from '@sentry/browser';
import { useApplicationId } from '../Shared/ApplicationProvider';
import { ADMISSIONS_EMAIL, ADMISSIONS_TELEPHONE } from '../constants';

const ALL_PROGRAMS_READY_DATE = new Date('2021-06-01');

export default function DashboardContent(props) {
  const applicationId = useApplicationId();
  const { data, cohort } = useCurrentApplication({
    fetchPolicy: 'cache-and-network',
  });

  const isDesktop = useContext(MediaQueryContext);

  const status = _get(data, 'application.Status__c');
  const approachDashboard = _get(data, 'application.approachDashboard');
  const startDate = _get(cohort, 'data.Date_Start__c');

  // Need to be confident its an old app meaning we have to wait for data
  const isUnsupportedApplication =
    !approachDashboard &&
    data &&
    !!Object.keys(data).length &&
    new Date(startDate) < ALL_PROGRAMS_READY_DATE;
  
    // there is a very subtle difference here, 
    // because !unsupportedApplication could mean 
    // the data is still loading, not that it is supported
  const isSupportedApplication = approachDashboard || new Date(startDate) > ALL_PROGRAMS_READY_DATE;

  const setSelectedCohort = () => {
    return {
      loading: cohort.loading,
      cohort: cohort.data || {},
    };
  };
  return (
    <StyledSection variant="dashboard">
      <DashboardNavigation section="Dashboard" />
      <Row>
        {isDesktop && (
          <>
            <CohortWidget span={1 / 2} {...setSelectedCohort()} />
            <IntroMessage />
          </>
        )}
        {!isDesktop && (
          <>
            <IntroMessage />
            <CohortWidget {...setSelectedCohort()} />
          </>
        )}
      </Row>
      {isSupportedApplication && (
        <>
          {status === 'Withdrawn' ? <WithdrawnApplication /> : <ActivityList />}
        </>
      )}
      {isUnsupportedApplication && <OldPortalApplication applicationId={applicationId} />}
    </StyledSection>
  );
}

const OldPortalApplication = ({ applicationId }) => {
  useEffect(() => {
    captureException(
      new Error(
        `Old portal application loaded in Approach, ID: ${applicationId}`,
      ),
    );
  }, [applicationId]);
  return (
    <Card icon={YellowAlert} header="Alert" collapsable={false} span={2 / 3}>
      <StyledP style={{ padding: '1.5rem' }}>
        Your most recent application was created in an older version of the
        Approach Dashboard that is no longer supported. For more information,
        please contact admissions at{' '}
        <Dmlink href={`mailto:${ADMISSIONS_EMAIL}`}>{ADMISSIONS_EMAIL}</Dmlink>{' '}
        or{' '}
        <span style={{ whiteSpace: 'nowrap' }}>
          <Dmlink
            href={`tel:+${ADMISSIONS_TELEPHONE.replace(/-/g, '')}`}
            style={{ textDecoration: 'none' }}
          >
            {ADMISSIONS_TELEPHONE}
          </Dmlink>
        </span>
        . To create a new application, go to{' '}
        <Dmlink href="https://devmountain.com/cohorts">
          devmountain.com/cohorts
        </Dmlink>{' '}
        and select a cohort.
      </StyledP>
    </Card>
  );
};
