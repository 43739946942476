import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from 'styled-components';
import CardSection from '../Styles/CardSection';
import _get from 'lodash/get';
import OriginalCheckbox from '../Shared/Checkbox';
import { Formik } from 'formik';
import StyledButton from '../Styles/StyledButton';
import { useSnackbar } from '../Shared/SnackbarProvider';


const GET_EEO_RESPONSES = gql`
  query GetEEOResponses {
    eeoResponses {
      gender
      ethnicity
      gender_other
      ethnicity_other
      veteranStatus
      disabilityStatus
    }
  }
`

const SAVE_EEO_RESPONSES = gql`
  mutation SaveEEOResponses(
    $gender: String
    $ethnicity: String
    $gender_other: String
    $ethnicity_other: String
    $veteranStatus: String
    $disabilityStatus: String
  ){
    saveEEOResponses(
      gender: $gender
      ethnicity: $ethnicity 
      gender_other: $gender_other
      ethnicity_other: $ethnicity_other
      veteranStatus: $veteranStatus 
      disabilityStatus: $disabilityStatus 
    ) {
      gender
      ethnicity
      gender_other
      ethnicity_other
      veteranStatus
      disabilityStatus 
    }
  }
`

const ethnicityOptions = [
  {
    label: "American Indian or Alaska Native",
    value: "American Indian or Alaska Native",
    id: "ethnicity-ai",
  },
  {
    label: "Asian",
    value: "Asian",
    id: "ethnicity-a",
  },
  {
    label: "Black, African, or African American",
    value: "Black, African, or African American",
    id: "ethnicity-ba",
  },
  {
    label: "Hispanic or Latino/a",
    value: "Hispanic or Latino/a",
    id: "ethnicity-ho",
  },
  {
    label: "Native Hawaiian or Pacific Islander",
    value: "Native Hawaiian or Pacific Islander",
    id: "ethnicity-nh",
  },
  {
    label: "White",
    value: "White",
    id: "ethnicity-w",
  },
  {
    label: "I Prefer Not to Say",
    value: "Decline to state",
    id: "ethnicity-i",
  },
]

export const genderOptions = [
  { value: "f", label: "Female", id: "gender-f" },
  { value: "m", label: "Male", id: "gender-m" },
  { value: "Non-binary", label: "Non-Binary", id: "gender-n" },
  { value: "prefer not to say", label: "I Prefer Not to Say", id: "gender-p" },
]

export default function VoluntaryDisclosures() {
  const { data, error } = useQuery(GET_EEO_RESPONSES);
  const [mutate,
    { loading: mutationLoading }
  ] = useMutation(SAVE_EEO_RESPONSES, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: GET_EEO_RESPONSES,
        data: {
          eeoResponses: data.saveEEOResponses
        },
      })
    }
  });
  const { openSnackbar } = useSnackbar()
  if (error) console.dir(error);

  if (!data || !data.eeoResponses) {
    return null;
  }

  const initialValues = {
    gender: _get(data, 'eeoResponses.gender') || '',
    gender_other: _get(data, 'eeoResponses.gender_other') || '',
    ethnicity: _get(data, 'eeoResponses.ethnicity') || '',
    ethnicity_other: _get(data, 'eeoResponses.ethnicity_other') || '',
    veteranStatus: _get(data, 'eeoResponses.veteranStatus') || '',
    disabilityStatus: _get(data, 'eeoResponses.disabilityStatus') || '',
  }
  return (
    <CardSection style={{ borderTop: '1px solid rgba(231,231,231,1)' }}>
      <StyledHeading>Voluntary Disclosures</StyledHeading>
      <p>To guide and inform the work we do, we like to monitor
      some of the diversity characteristics of our students.
      Answering these questions is not required and will
      have no effect on admissions or class status. If you choose
      to participate, your individual answers will be kept private.</p>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          mutate({ variables: values }).then((response) => {
            resetForm({ values: _get(response, 'data.saveEEOResponses') });
          }).catch(err => {
            openSnackbar('An error occurred while saving. Please try again.')
          })
        }}
      >
        {({ dirty, resetForm, submitForm, handleBlur, handleChange, values }) => <>
          <div>
            <StyledSubheading>Please select your gender.</StyledSubheading>
            {genderOptions.map(option => <Checkbox key={option.id} variant="radio" name="gender" {...option} />)}
            <Checkbox variant="radio" value="other" label={<>Let Me Type
            <input name="gender_other" disabled={values.gender !== "other"} style={{ marginLeft: '0.5rem' }} onChange={handleChange} onBlur={handleBlur}
                value={values.gender === 'other' ? values.gender_other : ''} /></>} name="gender" id="gender-lmt" />
          </div>
          <div>
            <StyledSubheading>Please select any racial or ethnic communities you are a part of.</StyledSubheading>
            {ethnicityOptions.map(option => <Checkbox key={option.id} variant="radio" name="ethnicity" {...option} />)}
            <Checkbox
              variant="radio"
              label={
                <>Let Me Type
              <input name="ethnicity_other" disabled={values.ethnicity !== "other"} style={{ marginLeft: '0.5rem' }}
                    onChange={handleChange} onBlur={handleBlur} value={values.ethnicity === 'other' ? values.ethnicity_other : ''} /></>}
              value="other"
              name="ethnicity"
              id="ethnicity-lmt" />

          </div>
          <div>
            <StyledSubheading>Do you have a disability?</StyledSubheading>
            <Checkbox
              variant="radio"
              name="disabilityStatus"
              id="disability-yes"
              label="Yes, I have a disability or a history of having a disability"
              value="Yes, I have a disability or a history of having a disability"
            />
            <Checkbox
              variant="radio"
              name="disabilityStatus"
              id="disability-no"
              label="No, I have not had a disability or a history of having a disability"
              value="No, I have not had a disability or a history of having a disability"
            />
            <Checkbox
              variant="radio"
              name="disabilityStatus"
              id="disability-decline"
              label="I prefer not to say"
              value="I prefer not to say"
            />
          </div>
          <div>
            <StyledSubheading>Are you a Veteran or Protected Veteran?</StyledSubheading>
            <Checkbox
              variant="radio"
              name="veteranStatus"
              id="veteran-no"
              label="I am not a veteran"
              value="I am not a veteran"
            />
            <Checkbox
              variant="radio"
              name="veteranStatus"
              id="veteran-yes"
              label="I am a protected veteran (disabled veteran, recently-separated veteran, active wartime or campaign badge veteran, or armed forces service medal veteran)"
              value="I am a protected veteran (disabled veteran, recently-separated veteran, active wartime or campaign badge veteran, or armed forces service medal veteran)"
            />
            <Checkbox
              variant="radio"
              name="veteranStatus"
              id="veteran-decline"
              label="I prefer not to say"
              value="I prefer not to say"
            />
          </div>
          <div style={{ padding: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
            <StyledButton
              disabled={!dirty || mutationLoading}
              onClick={() => resetForm({ values: initialValues })}
              style={{ marginRight: '1rem' }}
              type="cancel"
              variant="secondary">
              Reset
            </StyledButton>
            <StyledButton
              disabled={!dirty || mutationLoading}
              onClick={submitForm}
              type="submit"
              variant="primary">
              Updat{mutationLoading ? 'ing...' : 'e'}
            </StyledButton>
          </div>
        </>}
      </Formik>
    </CardSection>
  );
}

const StyledHeading = styled.h2`
  font-weight: bold;
  font-size: 0.9375rem;
  margin-bottom: 1rem;
`;

const StyledSubheading = styled.h3`
  font-weight: bold;
  font-size: 0.875rem;
  margin-top: 1rem;
`
const Checkbox = (props) => <OriginalCheckbox labelStyle={{ padding: '0' }} {...props} />