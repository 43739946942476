import React from 'react';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';
import _get from 'lodash/get';

import P, { StyledPContainer } from '../Styles/StyledP';
import { useCurrentApplication } from '../utils/hooks';
import pivotCourses from '../utils/pivotCourses';
import SalesforceCopy from '../Shared/SalesforceCopy';


export default function AgreementWidget({ id }) {
  const application = useCurrentApplication();
  const programName = _get(application, 'data.application.enrollment.cohort.programName');
  const isPivotCourse = !!pivotCourses[programName];
  return (
    <Card
      span={2 / 3}
      header="student agreement"
      defaultExpanded
      id="student-agreement"
    >
      <CardSection>
        <SalesforceCopy
          field="studentAgreement"
          component={StyledPContainer}
          fallback={<OldStaticContent isPivotCourse={isPivotCourse} />}
        />
      </CardSection>
    </Card>
  );
}

const OldStaticContent = ({ isPivotCourse }) => <P variant="paragraph">
  Before you start class, you'll need to sign your Student Agreement.
  Read it carefully. Not only does it have a riveting section about
  hygiene, it&apos;s also filled with important information.
{isPivotCourse && <>{' '}Your student
agreement will be emailed to you for signature shortly after admission.</>}
</P>