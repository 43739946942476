import styled from 'styled-components';

const StyledA = styled.a`
  color: ${props => props.theme.colors.blue};
`;

export const Dmlink = styled.a`
  color: #0eb6fe;
  text-decoration: none;
`;

export default StyledA;
