import React from 'react';
import { ErrorMessage } from 'formik';
import StyledH2 from '../Styles/StyledH2';
import StyledLegend from '../Styles/StyledLegend';
import Checkbox from '../Shared/Checkbox';
import { ErrorSpan } from '../Shared/Input';
import { SectionContainer, Content } from './Contact';

const courseTypeDictionary = {
  'After Hours': 'part-time',
  Immersive: 'full-time',
};

export default function Acknowledgement({
  lengthInWeeks,
  courseType,
  timeZone,
}) {
  return (
    <>
      <SectionContainer style={{ marginTop: '2rem' }}>
        <StyledH2 style={{ lineHeight: '1.5626rem', letterSpacing: '0.43px' }}>
          Acknowledgement: <br />
          Bootcamps are hard!
        </StyledH2>
        <Content style={{ marginTop: '1rem' }}>
          <StyledLegend>
            The application you&apos;re submitting is for admission to a
            training course. It is not an application for employment.
          </StyledLegend>
          <Checkbox
            name="nonEmploymentAcknowledgement"
            id="nonEmploymentAcknowledgement"
            label="I Understand"
            labelStyle={{
              color: 'black',
              textTransform: 'uppercase',
              fontFamily: 'Libre Franklin',
              fontWeight: 'bold',
              letterSpacing: '0.008125rem',
            }}
          />
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ visibility: 'hidden' }}>*</span>
            <ErrorMessage name="nonEmploymentAcknowledgement">
              {err => <ErrorSpan>{err}</ErrorSpan>}
            </ErrorMessage>
          </div>
        </Content>
      </SectionContainer>
      <SectionContainer>
        <Content>
          <StyledLegend>
            The course you&apos;re applying for meets for {lengthInWeeks} weeks,
            on a strict, {courseTypeDictionary[courseType]} {timeZone}{' '}
            {timeZone && 'Time Zone '}
            schedule and requires additional studying during evenings and
            weekends. Students can&apos;t take vacations or skip class. It's not
            for the faint of heart.
          </StyledLegend>
          <Checkbox
            name="acknowledgement"
            id="acknowledgement"
            label="I Understand"
            labelStyle={{
              color: 'black',
              textTransform: 'uppercase',
              fontFamily: 'Libre Franklin',
              fontWeight: 'bold',
              letterSpacing: '0.008125rem',
            }}
          />
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ visibility: 'hidden' }}>*</span>
            <ErrorMessage name="acknowledgement">
              {err => <ErrorSpan>{err}</ErrorSpan>}
            </ErrorMessage>
          </div>
        </Content>
      </SectionContainer>
    </>
  );
}
