import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SuccessIcon from '../img/SuccessIcon';

export default function TodoItem({ complete, children, to, action }) {
  return (
    <StyledLi>
      <StyledA href={`#${to}`}>
        {/* I tried just using visibility: hidden for this
        but it wouldn't work and I couldn't figure out why */}
        {complete ? (
          <SuccessIcon color="rgba(12,202,142,1)" />
        ) : (
          <span style={{ width: '1.125rem' }} />
        )}
        <StyledSpan>
          <ItemSpan complete={complete}>{children}</ItemSpan>{' '}
          {complete ? (
            <DoneIcon>Done</DoneIcon>
          ) : (
            <ViewIcon>{action || 'View'}</ViewIcon>
          )}
        </StyledSpan>
      </StyledA>
    </StyledLi>
  );
}

TodoItem.propTypes = {
  complete: PropTypes.bool,
  to: PropTypes.string,
  action: PropTypes.string,
};

TodoItem.defaultProps = {
  complete: false,
  to: '',
  action: '',
};

const StyledLi = styled.li`
  list-style: none;

  :nth-of-type(1n) {
    background: rgba(248, 248, 248, 1);
  }

  :nth-of-type(2n) {
    background: white;
  }
`;

const StyledA = styled.a`
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  padding: 1.375rem 1rem;
  text-decoration: none;
  color: unset;
`;

const StyledSpan = styled.span`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
`;

const DoneIcon = styled.div`
  font-weight: 600;
  font-size: 0.6875rem;
  padding: 0.1875rem 0.5rem;
  text-decoration: none;
  border-radius: 0.1875rem;
  border-width: 0.0625rem;
  border-style: solid;
  border-color: rgba(12, 202, 142, 1);
  background: rgba(12, 202, 142, 1);
  color: ${({ theme }) => theme.colors.white};
`;

const ViewIcon = styled(DoneIcon)`
  background: none;
  color: ${({ theme }) => theme.colors.lightblue};
  border-color: ${({ theme }) => theme.colors.lightblue};

  &:active,
  &:hover {
    background: ${({ theme }) => theme.colors.lightblue};
    color: white;
  }
`;

const ItemSpan = styled.span`
  padding-left: 0.125rem;
  ${({ complete, theme }) =>
    complete
      ? `
  text-decoration: line-through;
  color: ${theme.colors.midgray};
  `
      : ''}
`;
