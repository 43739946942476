import React, { useContext } from 'react';
import { Form, ErrorMessage, Formik } from 'formik';
import SaveAndContinue from '../Shared/SaveAndContinue';
import styled from 'styled-components';
import * as Yup from 'yup';
import _get from 'lodash/get';
import StyledH2 from '../Styles/StyledH2';
import Input, { ErrorSpan, SpacingSpan } from '../Shared/Input';
import StyledSection from '../Styles/StyledSection';
import Radio from '../Shared/Radio';
import Select from '../Shared/Select';
import { ApplicationContextDetails } from '../Shared/ApplicationProvider';
import { showErrors } from '../utils/jsFunctions';
import Loading from '../Shared/Loading';
import ErrorScroll from '../Shared/ErrorScroll';
import { useCompletionEffect, useCurrentApplication } from '../utils/hooks';
import StyledP from '../Styles/StyledP';
// INFO: Leaving in case Sunny wants to reverse decision
// import WarningModal from '../Shared/WarningModal';
import { useValidation } from '../Shared/ValidationProvider';
import { useSnackbar } from '../Shared/SnackbarProvider';
import { genderOptions } from '../Account/VoluntaryDisclosures';

const tShirtSizes = ['xs', 's', 'm', 'l', 'xl', 'xxl'];

const setInitialValues = data => {
  const application = _get(data, 'application') || {};
  return {
    applicantType: application.applicantType || 'us',
    mailingAddress1: application.mailingAddress1 || '',
    city: application.city || '',
    region: application.region || '',
    postalCode: application.postalCode || '',
    country: application.country || 'US',
    phone: application.phone || '',
    birthday: application.birthday || '',
    gender: application.gender || '',
    housing: application.housing || '',
    size: application.size || '',
  };
};

const validationObj = {
  applicantType: Yup.string()
    .oneOf(['us', 'international'])
    .required('Choose US or International'),
  mailingAddress1: Yup.string()
    .trim()
    .required('Enter a valid mailing address')
    .max(255, 'Max character limit 255'),
  city: Yup.string()
    .trim()
    .required('Enter a valid city')
    .max(40, 'Max character limit 40'),
  country: Yup.string().required('Enter a valid country'),
  region: Yup.string()
    .trim()
    .required('Enter a valid state or region')
    .max(255, 'Max character limit 255'),
  postalCode: Yup.string()
    .trim()
    .required('Enter a valid zip or postal code')
    .max(10, 'Max character limit 10'),
  phone: Yup.string()
    .trim()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Enter a valid phone number',
    )
    .required('Enter a valid phone number'),
  birthday: Yup.date()
    .required('Enter a valid birthday in mm/dd/yyyy format')
    .typeError('Enter a valid birthday in mm/dd/yyyy format'),
  gender: Yup.string().required('Enter a valid gender'),
  size: Yup.string()
    .oneOf(tShirtSizes)
    .required('Enter a valid t-shirt size.'),
};

const validationSchema = Yup.object().shape(validationObj);
const validationObjWithHousing = {
  ...validationObj,
  housing: Yup.string()
    .oneOf(
      ['Not interested', 'Interested in housing'],
      'Choose your interest in housing.',
    )
    .required('Choose your interest in housing.'),
};

const validationSchemaWithHousing = Yup.object().shape(
  validationObjWithHousing,
);

const Contact = props => {
  const errorContext = useValidation();
  const { localValues, setLocalValues } =
    useContext(ApplicationContextDetails) || {};
  const { data, loading, cohort } = useCurrentApplication();
  const { openSnackbar } = useSnackbar();
  const stateEnrollmentRestrictions =
    _get(cohort, 'data.stateEnrollmentRestrictions') || '';
  useCompletionEffect(data);

  if (loading) {
    return <Loading />;
  }

  const programName = _get(cohort, 'data.programName');
  const locationName = _get(cohort, 'data.locationName');
  const programOffersHousing =
    programName === 'On Ground Web Development Full-Time';
  const locationOffersHousing = ['Dallas', 'Lehi'].includes(locationName);
  const cohortOffersHousing = programOffersHousing && locationOffersHousing;

  return (
    <Formik
      initialValues={setInitialValues(data)}
      validationSchema={() => {
        return cohortOffersHousing
          ? validationSchemaWithHousing
          : validationSchema;
      }}
      onSubmit={(values, { setSubmitting }) => {
        openSnackbar('Contact saved', {
          closeCb: () => {
            setSubmitting(false);
            props.navigate('../education');
          },
        });
        setLocalValues({
          ...localValues,
          application: { ...localValues.application, ...values },
        });
      }}
    >
      {formik => {
        const hasErrors = showErrors('contact', formik);
        if (hasErrors && !errorContext.errors.contact) {
          errorContext.setErrors('contact', true);
        }
        if (!hasErrors && errorContext.errors.contact) {
          errorContext.setErrors('contact', false);
        }

        return (
          <Form>
            <StyledSection>
              <SectionContainer>
                <StyledH2>Contact Information</StyledH2>
                <Content>
                  <ApplicantType>
                    <Radio
                      labelStyle={{
                        padding: '0',
                        fontSize: '0.875rem',
                      }}
                      name="applicantType"
                      id="us"
                      value="us"
                      label="US Applicants"
                      onChange={() => {
                        formik.setFieldValue('country', 'US');
                        formik.setFieldValue('applicantType', 'us');
                      }}
                    />
                    <Radio
                      labelStyle={{
                        padding: '0',
                        fontSize: '0.875rem',
                      }}
                      name="applicantType"
                      id="international"
                      value="international"
                      label="International Applicants"
                      onChange={() => {
                        formik.setFieldValue('country', '');
                        formik.setFieldValue('applicantType', 'international');
                      }}
                    />
                  </ApplicantType>
                  {_get(formik, 'values.applicantType') === 'international' && (
                    <Input placeholder="Country" name="country" />
                  )}
                  <Input placeholder="Mailing Address" name="mailingAddress1" />
                  <Input name="city" placeholder="City" />
                  <DivContainer>
                    <DivInputContainer>
                      {formik.values.applicantType === 'international' ? (
                        <Input
                          name="region"
                          placeholder={
                            formik.values.applicantType === 'international'
                              ? 'Region'
                              : 'State'
                          }
                        />
                      ) : (
                        <Select
                          name="region"
                          value={formik.values.region}
                          helperText={
                            stateEnrollmentRestrictions
                              ? `There are currently restrictions in place that prevent us from admitting students from the following states: ${stateEnrollmentRestrictions}.`
                              : ''
                          }
                        >
                          <option value="" hidden disabled>
                            State
                          </option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                          <option value="International">International</option>
                        </Select>
                      )}
                    </DivInputContainer>
                    <DivInputContainer>
                      <Input name="postalCode" placeholder="Zip Code" />
                    </DivInputContainer>
                  </DivContainer>

                  <Input
                    name="phone"
                    placeholder="Phone"
                    onChange={e => {
                      let unformatted = e.target.value.replace(/\D/g, '');
                      let formatted;

                      if (unformatted.length === 10) {
                        formatted = unformatted.replace(
                          /(\d{3})(\d{3})(\d{4})/,
                          '($1) $2-$3',
                        );
                      } else if (unformatted.length === 11) {
                        formatted = unformatted.replace(
                          /(1)(\d{3})(\d{3})(\d{4})/,
                          '$1 ($2) $3-$4',
                        );
                      }
                      formik.setFieldValue(
                        'phone',
                        formatted || e.target.value,
                      );
                    }}
                  />
                </Content>
              </SectionContainer>
              {cohortOffersHousing && (
                <SectionContainer>
                  <StyledH2>Housing</StyledH2>
                  <Content>
                    <StyledP
                      style={{
                        marginBottom: '1.5rem',
                        fontSize: '0.9375rem',
                        letterSpacing: '0.08px',
                      }}
                    >
                      Limited student housing is available for full-time
                      in-person web programs. Priority is given to students who
                      enroll early.
                    </StyledP>
                    <Radio
                      name="housing"
                      id="notInterested"
                      value="Not interested"
                      label="Not interested in housing"
                    />
                    <Radio
                      name="housing"
                      id="interested"
                      value="Interested in housing"
                      label="Interested in housing"
                    />
                    <SpacingSpan>*</SpacingSpan>
                    <ErrorSpan>
                      <ErrorMessage name="housing" />
                    </ErrorSpan>
                  </Content>
                </SectionContainer>
              )}
              <SectionContainer>
                <StyledH2>Personal Information</StyledH2>
                <Content>
                  <BirthdayInput
                    onFocus={e => (e.target.placeholder = '')}
                    onBlur={e =>
                      !formik.values.birthday
                        ? (e.target.placeholder = 'Birthday')
                        : null
                    }
                    // TODO: finish this Adding to handle Safari not handling input date
                    // onChange={e =>
                    //   formik.setFieldValue(
                    //     'birthday',
                    //     formatDate(e.target.value),
                    //   )
                    // }
                    name="birthday"
                    placeholder="Birthday"
                    type="date"
                  />

                  <Select name="gender" value={formik.values.gender}>
                    <option value="" hidden disabled>
                      Gender
                    </option>
                    {genderOptions.map(gender => (
                      <option key={gender.value} value={gender.value}>
                        {gender.label}
                      </option>
                    ))}
                    {/* <option value="other">Let Me Type</option> */}
                  </Select>
                  <Select name="size" value={formik.values.size}>
                    <option value="" hidden disabled>
                      T-Shirt Size
                    </option>
                    {tShirtSizes.map(size => (
                      <option key={size} value={size}>
                        {size.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                </Content>
              </SectionContainer>
              <SectionContainer>
                <Content>
                  <SaveAndContinue disabled={formik.isSubmitting} />
                </Content>
              </SectionContainer>
              <ErrorScroll />
            </StyledSection>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Contact;

const Disclaimer = styled.p`
  font-family: 'Libre Franklin';
  font-weight: 300;
  font-size: 11px;
  letter-spacing: 0.08px;
  line-height: 15px;

  b {
    font-weight: 600;
  }

  @media (min-width: 1024px) {
    margin-bottom: 0.75rem;
    width: 57%;
  }
`;

const DivContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ApplicantType = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  @media (min-width: 1024px) {
    padding: 0.8125rem 0;
  }
`;

const DivInputContainer = styled.div`
  width: 50%;
  @media (min-width: 1024px) {
    width: 100%;
  }
`;

const BirthdayInput = styled(Input)`
  &[value=''][placeholder='Birthday'] {
    color: rgb(117, 117, 117);
    ::before {
      padding-right: 0.5rem;
      content: 'Birthday';
    }
  }
`;

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const Content = styled.div`
  @media (min-width: 1024px) {
    width: 57%;
  }
`;

// function formatDate(num) {
//   let addSlash = (str, len) => {
//     if (str && str.length === len) return str + '/';
//     return str;
//   };

//   let baseVal = num.replace(/\//g, '');
//   let mm = addSlash(baseVal.substring(0, 2), 2);
//   let dd = addSlash(baseVal.substring(2, 4), 2);
//   let yy = baseVal.substring(4, 8);

//   return mm + dd + yy;
// }
