import React from 'react';
import Card from '../../Shared/Card';
import styled from 'styled-components'
import ApplicationProgress from './ApplicationProgress';
import AdmissionsProgress from './AdmissionsProgress';
import FinanceProgress from './FinanceProgress';
import OnboardingProgress from './OnboardingProgress';
import PartyProgress from './PartyProgress';
import PreCourseProgress from './PreCourseProgress';
import ScholarshipApplicationProgress from './ScholarshipApplicationProgress';

export default function ActivityList(props) {
    return <Card span={2/3} collapsable={false} header="Activity List">
        <StyledOl>
            <ApplicationProgress />
            <ScholarshipApplicationProgress />
            <AdmissionsProgress />
            <FinanceProgress />
            <OnboardingProgress />
            <PreCourseProgress />
            <PartyProgress />
        </StyledOl>
    </Card>
}


const StyledOl = styled.ol`
  display: flex;
  flex-direction: column;
  padding: 0 1.375rem;
`;
