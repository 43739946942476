import React from 'react';
import styled from 'styled-components';
import NavLink from './NavLink';
import HomeIcon from '../img/HomeIcon';
import sectionIcons from '../img/sectionIcons';

export default function DashboardNavigation(props) {
  const Icon = sectionIcons[props.section];

  return (
    <StyledNav>
      <NavLink to={`/dashboard`}>
        <StyledSpan highlighted={props.section === 'Dashboard'}>
          <HomeIcon />
          <span style={{ paddingLeft: '0.375rem' }}>Dashboard</span>
        </StyledSpan>
      </NavLink>

      <CurrentSpan>
        {props.section && props.section !== 'Dashboard' && (
          <>
            <Icon />
            <h1 style={{ paddingLeft: '0.25rem' }}>{props.section}</h1>
          </>
        )}
      </CurrentSpan>
    </StyledNav>
  );
}

const StyledSpan = styled.span`
  color: ${({ highlighted, theme }) =>
    highlighted ? theme.colors.blue : theme.colors.midgray};
  font-weight: 600;
  font-size: 0.8125rem;
`;

const CurrentSpan = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkestgray};
  font-size: 1.125rem;
  font-weight: 500;
  /* minHeight to maintain spacing 
  consistency if no icon */
  min-height: 2.5rem;
`;

const StyledNav = styled.nav`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0 1.5rem;
`;
