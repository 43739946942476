import { Link } from '@reach/router';
import React from 'react';
import styled from 'styled-components';
import Card from '../Shared/Card';
import DashboardNavigation from '../Shared/DashboardNavigation';
import StyledButton from '../Styles/StyledButton';
import StyledSection from '../Styles/StyledSection';

export default function ScholarshipPending() {
  return (
    <StyledSection variant="dashboard">
      <div>
        <DashboardNavigation section="Scholarship Application" />
        <Card span={2 / 3} collapsable={false}>
          <CardSection>
            <strong>Your scholarship application is under review.</strong>
            <div style={{ padding: '1rem 0 2rem 0' }}>
              <StyledButton variant="secondary" leftIcon as={Link} to="..">
                back to dashboard
              </StyledButton>
            </div>
          </CardSection>
        </Card>
      </div>
    </StyledSection>
  );
}

const CardSection = styled.section`
  padding: 0 1rem;
  line-height: 1.375rem;
  font-size: 0.875rem;
  strong {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkestgray};
  }
`;
