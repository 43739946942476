import React from 'react';
import styled from 'styled-components';
import { connect, Field } from 'formik';

const StyledInput = styled.input`
::placeholder {
  color: #a6a6a6;
}
  -webkit-appearance: none;
  border-radius: 1px;
  display: block;
  border: 0.0625rem solid ${({ theme }) => theme.colors.lightgray};
  font-size: 1rem;
  /* line-height: 3.125rem; */
  padding: 1.0625rem 1rem;
  width: 100%;
  height: 3.125rem;
  color: ${({ theme }) => theme.colors.darkblue};
  :focus {
    /* Adding a 0.5pt outline instead of 
    changing border width so the size of 
    the element doesn't change and cause
    any jumping around */
    border-color: rgba(14, 182, 254, 1);
    outline: 0.03125rem solid rgba(14, 182, 254, 1);
  }
`;

function SmartStyledInput({ formik, ...props }) {
  return Object.keys(formik).length > 0 ? (
    <StyledInput as={Field} {...props} />
  ) : (
      <StyledInput {...props} />
    );
}

export default connect(SmartStyledInput);
