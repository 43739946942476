import React, { useContext, useState } from 'react';
import ReactModal from 'react-modal';
import StyledSection from '../Styles/StyledSection';
import StyledH2 from '../Styles/StyledH2';import StyledP from '../Styles/StyledP';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { ApplicationContext } from '../Shared/ApplicationProvider';
import Loading from '../Shared/Loading';
import SaveAndContinue from '../Shared/SaveAndContinue';
import { SectionContainer, Content } from './Contact';
import WarningModal from '../Shared/WarningModal';
import _get from 'lodash/get';
import SalesforceCopy from '../Shared/SalesforceCopy';

const GET_SKILL_REVIEW_COMPLETION = gql`
  query GetSkillReviewCompletion($id: String!) {
    application(id: $id) {
      id
      skillReviewLink
      skillReviewUnlocked
      complete {
        skillReviewSubmitted
      }
    }
  }
`;

export default function SkillReview() {
  const applicationId = useContext(ApplicationContext);
  const [isOpen, setIsOpen] = useState(false);
  const { loading, data } = useQuery(GET_SKILL_REVIEW_COMPLETION, {
    variables: { id: applicationId },
  });
  if (loading) {
    return <Loading />;
  }

  ReactModal.setAppElement('#root');

  const closeModal = () => setIsOpen(false);

  return (
    <>
      <WarningModal
        isOpen={isOpen}
        closeModal={closeModal}
        heading="Oops...Mobile not supported"
        body="HackerRank does not support testing on a mobile phone. Please continue with a laptop or desktop computer using Google Chrome or Mozilla Firefox."
      />
      <StyledSection>
        <SectionContainer>
          <StyledH2>Skill Review</StyledH2>
          <Content>
            {_get(data, 'application.complete.skillReviewSubmitted') ? (
              <>
                <StyledP variant="paragraph">
                  Your application is now complete and your admissions decision
                  should be ready in 1-2 business days. You should hear from us
                  soon, and keep an eye on your Approach Dashboard for any
                  notifications.
                </StyledP>
                <StyledP variant="paragraph">
                  Good luck! (We might be just a bit biased, but we&apos;re
                  really rooting for you.)
                </StyledP>
              </>
            ) : (
              <>
                <SalesforceCopy field="skillReviewInstructions" />
              </>
            )}
          </Content>
        </SectionContainer>
        <SectionContainer>
          <Content>
            <SaveAndContinue asLink to="/dashboard" />
          </Content>
        </SectionContainer>
      </StyledSection>
    </>
  );
}

