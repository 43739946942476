import React from 'react';
import styled from 'styled-components';
import StyledP from '../Styles/StyledP';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';
import { Link } from '@reach/router';
import SalesforceCopy from '../Shared/SalesforceCopy';

export default function EquipmentCard({ remote }) {
  return (
    <Card span={1 / 2} header="equipment" defaultExpanded>
      <CardSection >
        <SalesforceCopy component={Container} field="equipment" fallback={outdatedStaticContent} />
        </CardSection>
    </Card>
  );
}

const ParagraphTag = styled(StyledP)`
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  font-size: 0.895rem;
  margin: 1rem 0;
`;

const Container = styled(StyledP)`
p {
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  font-size: 0.895rem;
  margin: 1rem 0;
}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.lightblue};
`;

const outdatedStaticContent = (<>
  <ParagraphTag>
    Students are expected to use their own computers for the duration of the
    program. Students are required to have computer and internet access during all normal
    class hourse (Monday - Friday 9am - 5pm) and should also have computer/internet access
    for roughly 20 hours per week outside of class. We recommend all student machines
    have 8GB RAM, 250 GB capacity hard or solid state drives, and a
    dual-core processor.
</ParagraphTag>
  <ParagraphTag>
    For additional questions regarding equipment please contact your
    course instructor or mentor. This information can be found in your
<StyledLink to="../onboarding"> student directory</StyledLink>.
</ParagraphTag>
</>)