import React from 'react';

export default function PencilWithSquareIcon(props) {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group 8</title>
      <desc>Created with Sketch.</desc>
      <g
        id="PencilWithSquareIcon-svgs-jonathan"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="PencilWithSquareIcon-Artboard"
          transform="translate(-222.000000, -141.000000)"
        >
          <g
            id="PencilWithSquareIcon-Group-8"
            transform="translate(222.000000, 141.000000)"
          >
            <g id="PencilWithSquareIcon-icon" fill="#0EB6FE">
              <g id="PencilWithSquareIcon-Group-4">
                <circle
                  id="PencilWithSquareIcon-Oval-16"
                  cx="20"
                  cy="20"
                  r="20"
                />
              </g>
            </g>
            <g
              id="PencilWithSquareIcon-pencil-write"
              transform="translate(8.000000, 8.000000)"
            >
              <g id="PencilWithSquareIcon-Frame_-_24px">
                <rect
                  id="PencilWithSquareIcon-Rectangle"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                />
              </g>
              <g
                id="PencilWithSquareIcon-Line_Icons"
                transform="translate(2.000000, 2.000000)"
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <g id="PencilWithSquareIcon-Group">
                  <path
                    d="M16,20 L2,20 C0.897,20 0,19.103 0,18 L0,4 C0,2.898 0.897,2 2,2 L9,2 L9,4 L2,4 L2,18 L16,18 L16,9 L18,9 L18,18 C18,19.103 17.103,20 16,20 Z"
                    id="PencilWithSquareIcon-Path"
                  />
                  <polygon
                    id="PencilWithSquareIcon-Path"
                    points="7.808 8.365 10.636 11.192 7.101 11.899"
                  />
                  <path
                    d="M18.414,3.414 C19.195,2.633 19.195,1.367 18.414,0.586 C17.633,-0.195 16.367,-0.195 15.586,0.586 L9.222,6.95 L12.051,9.779 L18.414,3.414 Z"
                    id="PencilWithSquareIcon-Path"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
