import React from 'react';
import PreCourseTodo from './PreCourseTodo';
import DashboardNavigation from '../Shared/DashboardNavigation';
import PrepMaterial from './PreCourseWebDevCard';
import FirstDay from './FirstDay';
import EquipmentCard from '../Onboarding/EquipmentCard';
import StyledSection from '../Styles/StyledSection';
import { Row } from '../FinanceCenter/FinanceCenter';
import { useApplicationId } from '../Shared/ApplicationProvider';
import { useCurrentApplication, useSalesforceCopy } from '../utils/hooks';
import _get from 'lodash/get';
import Loading from '../Shared/Loading';

const PREP_MATERIAL = 'prepMaterial';
const DAY_ONE = 'dayOne';

export default function PrecourseWork() {
  const id = useApplicationId();
  const { cohort } = useCurrentApplication();
  const isRemote = _get(cohort, 'data.isRemote');
  const [{ loading }, { hasContent: hasPrepMaterialContent }] = useSalesforceCopy(PREP_MATERIAL)
  const [, { hasContent: hasFirstDayContent }] = useSalesforceCopy(DAY_ONE)
  return (loading ? <Loading /> :
    <StyledSection variant="dashboard">
      <DashboardNavigation section="Pre-Course Work" />
      <Row>
        <PreCourseTodo applicationId={id} />
      </Row>
      <Row>
        {hasPrepMaterialContent && <PrepMaterial applicationId={id} />}
        <EquipmentCard remote={isRemote} />
      </Row>
      {hasFirstDayContent && (
        <Row>
          <FirstDay />
        </Row>
      )}
    </StyledSection>
  );
}
