import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import styled from 'styled-components';
import { Redirect } from '@reach/router';
import gql from 'graphql-tag';
import { Formik } from 'formik';
import Loading from '../Shared/Loading';
import Checkbox from '../Shared/Checkbox';
import { useCurrentApplication } from '../utils/hooks';
import _get from 'lodash/get';

const UPDATE_ENROLLMENT = gql`
  mutation UpdateEnrollment($updates: EnrollmentInput) {
    updateEnrollment(updates: $updates)
  }
`;

export default function FinanceAcknowledgement(props) {
  const { data, loading, error } = useCurrentApplication({
    returnPartialData: false,
  });
  const [mutate] = useMutation(UPDATE_ENROLLMENT, {
    refetchQueries: ['GetApplication'],
  });
  if (loading) return null;
  if (error) {
    console.dir(error);
    return 'Error';
  }
  if (!data) return <Loading />;
  if (!data.application || !data.application.enrollment) {
    console.warn('No enrollment for application redirecting to dashboard');
    return <Redirect to={`/dashboard`} />;
  }

  return (
    <>
      <Formik
        initialValues={{
          deposit:
            _get(
              data,
              'application.enrollment.payments.deposit.acknowledged',
            ) || false,
          tuition:
            _get(
              data,
              'application.enrollment.payments.tuition.acknowledged',
            ) || false,
        }}
      >
        {formik => {
          return (
            <>
              <StyledP>
                Your seat deposit is due by{' '}
                <StyledEm>
                  {moment(
                    _get(data, 'application.enrollment.payments.deposit.due'),
                  ).format('MMMM D, YYYY')}
                </StyledEm>{' '}
                and is needed to hold your seat in the class. If you&apos;re
                having trouble with your deposit, please contact{' '}
                <StyledA href="mailto:admissions@devmountain.com">
                  admissions@devmountain.com
                </StyledA>{' '}
                <Checkbox
                  onChange={() => {
                    mutate({
                      variables: {
                        updates: {
                          id: _get(data, 'application.enrollment.id'),
                          depositAcknowledgement: true,
                        },
                      },
                    }).then(console.dir);
                    formik.setFieldValue('deposit', true);
                  }}
                  id="deposit"
                  name="deposit"
                  label="I Understand"
                  labelStyle={{
                    textTransform: 'uppercase',
                    fontFamily: 'Libre Franklin',
                    fontWeight: 'bold',
                    fontSize: '0.75rem',
                    letterSpacing: '0.008125rem',
                  }}
                />
              </StyledP>
              <StyledP>
                Full tuition of{' '}
                {_get(
                  data,
                  'application.enrollment.payments.tuition.amount',
                  0,
                ).toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'USD',
                })}{' '}
                is due before or when class begins on{' '}
                {moment(
                  _get(data, 'application.enrollment.payments.tuition.due'),
                ).format('MMMM D, YYYY')}
                .
                <Checkbox
                  onChange={() => {
                    mutate({
                      variables: {
                        updates: {
                          id: _get(data, 'application.enrollment.id'),
                          tuitionAcknowledgement: true,
                        },
                      },
                    });
                    formik.setFieldValue('tuition', true);
                  }}
                  id="tuition"
                  name="tuition"
                  label="I Understand"
                  labelStyle={{
                    textTransform: 'uppercase',
                    fontFamily: 'Libre Franklin',
                    letterSpacing: '0.008125rem',
                    fontWeight: 'bold',
                    fontSize: '0.75rem',
                  }}
                />
              </StyledP>
            </>
          );
        }}
      </Formik>
    </>
  );
}

const StyledEm = styled.em`
  font-weight: bold;
`;

const StyledP = styled.p`
  padding-bottom: 1.25rem;
`;

const StyledA = styled.a`
  color: ${({ theme }) => theme.colors.lightblue};
`;
