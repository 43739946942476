import React, { useContext } from 'react';
import Card from '../Shared/Card';
import StyledP from '../Styles/StyledP';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER } from '../Graphql/queries';
import { MediaQueryContext } from '../Shared/MediaQueryProvider';
import CardSection from '../Styles/CardSection';
import _get from 'lodash/get';

export default function IntroMessage(props) {
  const { data } = useQuery(GET_USER);

  const first_name = _get(data, 'user.first_name', '');
  const last_name = _get(data, 'user.last_name', '');

  const isDesktop = useContext(MediaQueryContext);

  const Container = isDesktop ? Card : ContainerDiv;
  const Content = isDesktop ? CardSection : StyledP;

  return (
    <Container
      span={1 / 2}
      header={
        <span style={{ color: '#0177A9' }}>
          {first_name} {last_name}
        </span>
      }
      collapsable={false}
    >
      <Content style={{ paddingTop: 0 }}>
        {!isDesktop ? (
          <>
            <StyledSpan>
              {first_name} {last_name},
            </StyledSpan>{' '}
            welcome{' '}
          </>
        ) : (
          <>Welcome </>
        )}
        to your Devmountain Approach dashboard! Your next step will always
        appear in the Activity List below.
      </Content>
    </Container>
  );
}

const StyledSpan = styled.span`
  color: ${props => props.theme.colors.darkestgray};
  font-family: ${props => props.theme.fonts.rale};
  font-weight: 700;
`;

const ContainerDiv = styled.div``;
