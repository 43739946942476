import React from 'react';
import styled from 'styled-components';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';
import ArrowRight from '../img/ArrowRight';
import slackLogo from '../img/slack.svg';
import StyledA from '../Styles/StyledA';
import { useSalesforceCopy } from '../utils/hooks';
import SalesforceCopy from '../Shared/SalesforceCopy';

const salesforceCopyField = 'slackSignup'

const DisplaySlackLogo = () => <SlackLogo src={slackLogo} />;
export default function SlackSignUpWidget({ applicationId }) {
  const [, {hasContent}] = useSalesforceCopy(salesforceCopyField)
  return (hasContent ? 
    <Card
      span={1 / 3}
      header="join slack"
      defaultExpanded
      icon={DisplaySlackLogo}
      id="join-slack"
    >
      <CardSection>
        <SalesforceCopy field={salesforceCopyField} />
      </CardSection>
    </Card> : null
  );
}

const SlackLogo = styled.img`
  width: 2.125rem;
  margin-right: 0.1875rem;
`;
