import { Link } from '@reach/router';
import React from 'react';
import _get from 'lodash/get';
import styled from 'styled-components';
import { captureException } from '@sentry/browser'
import Card from '../Shared/Card';
import DashboardNavigation from '../Shared/DashboardNavigation';
import StyledButton from '../Styles/StyledButton';
import StyledSection from '../Styles/StyledSection';
import { useCurrentApplication } from '../utils/hooks';

export default function ScholarshipApproved() {
  const {data} = useCurrentApplication();
  const award = _get(data, 'application.scholarshipApplication.award');
  React.useEffect(() => {
    if (!award){
      captureException(new Error('No award on approved page: ' + award ))
    }
  }, [award])
  return (
    <StyledSection variant="dashboard">
      <div>
        <DashboardNavigation section="Scholarship Application" />
        <Card span={2 / 3} collapsable={false}>
          <CardSection>
            <div>
              <strong>Congratulations, you have been selected to receive Devmountain's
              Adversity Scholarship!</strong> We are impressed by your determination and
              achievement in the face of adversity, and are excited to see where
              a Devmountain education takes you!
            </div>
            <div style={{marginTop: '1.5rem'}}>
              This scholarship may be used at any Devmountain campus for any
              Devmountain program to reduce tuition by <strong>${award}</strong>. Scholarship reward is not a
              guarantee of acceptance into a Devmountain program. Scholarship
              recipients must apply for, and be accepted into, a Devmountain
              program that begins within 120 days of notification of scholarship
              reward to use their scholarship—if a granted scholarship is not
              used within this time period, it will expire. Scholarship
              applicants must be at least 18 years old. Scholarships are
              non-transferrable and may be combined with other offers, up to,
              but not exceeding, the full tuition value of the program.
            </div>

            <div style={{ padding: '1rem 0 2rem 0' }}>
              <StyledButton variant="secondary" leftIcon as={Link} to="..">
                back to dashboard
              </StyledButton>
            </div>
          </CardSection>
        </Card>
      </div>
    </StyledSection>
  );
}
const CardSection = styled.section`
  padding: 0 1rem;
  line-height: 1.375rem;
  font-size: 0.875rem;
  strong {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkestgray};
  }
`;