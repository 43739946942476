import React from 'react';
import styled from 'styled-components';
import SlackLogo from '../img/slack.svg';
// import Envelope from '../img/email_24px.svg';
import StyledA from '../Styles/StyledA';

export default function DirectoryContactInfo(props) {
  const { firstName, lastName, slack, picture, email, position } = props;
  return (
    <ContactInfoContainer>
      <ThumbnailImg thumbnailImg={picture && picture} />
      <NameContainer>
        <DirectoryName>
          {firstName} {lastName}
        </DirectoryName>
        {position && <Position>{position}</Position>}
      </NameContainer>
      <LinkContainer>
        {/* <EmailLetter src={Envelope} /> */}
        <DirectoryEmail href={`mailto:${email}`}>
          {email
            .split('@')
            .map((section, i) =>
              i === 0 ? (
                <span key={`${section}.${i}`}>{section}</span>
              ) : (
                <span key={`${section}.${i}`}>@{section}</span>
              ),
            )}
        </DirectoryEmail>
      </LinkContainer>
      {slack && (
        <LinkContainer>
          <DirectorySlackLogo src={SlackLogo} />
          <DirectorySlack>
            {slack[0] !== '@' && '@'}
            {slack}
          </DirectorySlack>
        </LinkContainer>
      )}
    </ContactInfoContainer>
  );
}

const ContactInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
  line-height: 1rem;
  padding: 0 0.5rem;
`;

const ThumbnailImg = styled.div`
  height: 4.0625rem;
  width: 4.0625rem;
  border-radius: 3.125rem;
  margin-bottom: 0.125rem;
  background-image: url(${({ thumbnailImg }) => thumbnailImg});
  background-size: cover;
`;

const DirectoryName = styled.h1`
  font-size: 0.9375rem;
  font-weight: bold;
  letter-spacing: 0.009375rem;
  color: ${({ theme }) => theme.colors.darkestgray};
  font-family: ${({ theme }) => theme.fonts.rale};
  padding: 0.1875rem 0;
`;
const Position = styled.div`
  text-align: center;
  letter-spacing: 0.006875;
  font-weight: 600;
  padding: 0.1875rem 0;
  font-size: 0.875rem;
`;

const NameContainer = styled.div`
  margin-top: 0.5rem;
`;

const DirectoryEmail = styled(StyledA)`
  font-size: 0.875rem;
  letter-spacing: 0.0063rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.darkestgray};
  font-family: ${({ theme }) => theme.fonts.rale};
  margin: 0.375rem 0;
  margin-left: 0.25rem;

  &:active {
    color: ${({ theme }) => theme.colors.lightblue};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.lightblue};
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const DirectorySlackLogo = styled.img`
  width: 1.25rem;
`;

const LinkContainer = styled.div`
  display: flex;
  /* justify-content: center;
    align-items: center; */
`;

const DirectorySlack = styled.div`
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.rale};
  color: ${({ theme }) => theme.colors.darkestgray};
  margin: 0.375rem 0;
  margin-left: 0.25rem;

  /* &:active {
    color: ${({ theme }) => theme.colors.lightblue};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.lightblue};
  } */
`;

// const EmailLetter = styled.img`
//   width: 1.25rem;
//   height: 1.25rem;
//   margin-top: 0.25rem;
// `;
