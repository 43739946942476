import { useEffect, useContext } from 'react';
import { calculateCompletion, getUtmParamsFromURL } from './jsFunctions';
import {
  useApplicationId,
  ApplicationContextDetails,
} from '../Shared/ApplicationProvider';
import { GET_APPLICATION } from '../Graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import { useValidation } from '../Shared/ValidationProvider';
import _get from 'lodash/get';
import gql from 'graphql-tag';
import * as Sentry from '@sentry/browser';
import { UTM_PARAMS_KEY } from '../constants';

export const useCompletionEffect = data => {
  const validation = useValidation();
  const setCompletion = validation.setCompletion;

  useEffect(() => {
    if (data && data.application) {
      calculateCompletion(data).then(result => {
        setCompletion(result);
      });
    }
  }, [data, setCompletion]);
};

export const useCurrentApplication = (config = {}) => {
  const id = useApplicationId();

  const local = useContext(ApplicationContextDetails);
  // moved skip down below to avoid getting an application without an id regardless of what config says
  // This will get an application if there is an id from useApplicationId
  const remote = useQuery(GET_APPLICATION, {
    variables: { id },
    ...config,
    skip: !id,
  });

  if (local.loading || _get(local, 'localValues.application.cohortId', '')) {
    return {
      loading: local.loading,
      data: local.localValues,
      cohort: local.cohort,
      user: local.user,
    };
  }

  if (id) {
    let cohortObj;
    const remoteCohort = _get(remote, 'data.application.cohort', null);
    if (remote.loading) {
      cohortObj = { loading: !remoteCohort, data: remoteCohort };
    } else if (remoteCohort) {
      cohortObj = { data: remoteCohort, loading: false };
    } else {
      cohortObj = { data: null, loading: false };
    }
    return { ...remote, cohort: cohortObj, user: local.user };
  }

  return {
    loading: local.loading,
    data: local.localValues,
    cohort: local.cohort,
    user: local.user,
  };
};

const SALESFORCE_COPY_QUERY = gql`
  query GetSalesforceCopy($applicationId: String) {
    websiteCopy(applicationId: $applicationId) {
      equipment
      dayOne
      prepMaterial
      studentAgreement
      discordInstructions
      skillReviewInstructions
      slackSignup
    }
  }
`;

export const useSalesforceCopy = field => {
  const applicationId = useApplicationId();
  const queryResult = useQuery(SALESFORCE_COPY_QUERY, {
    variables: {
      applicationId,
    },
  });

  const { data, error } = queryResult;

  const innerHTML = {
    __html: _get(data, `websiteCopy.${field}`),
  };

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return [queryResult, { innerHTML, hasContent: !!innerHTML.__html }];
};

export const useSetUtmParamsInLocalStorage = () => {
  useEffect(() => {
    // capture utm params in localStorage so we dont lose them on navigation
    const params = getUtmParamsFromURL();
    if (params && typeof params === 'object') {
      try {
        localStorage.setItem(UTM_PARAMS_KEY, JSON.stringify(params));
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  }, []);
};
