import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import StyledSection from '../Styles/StyledSection';
import StyledH2 from '../Styles/StyledH2';
import StyledP from '../Styles/StyledP';
import ContactButton from '../Shared/ContactButton';
import { UnstyledButton } from '../Styles/StyledButton';
import homeImg from '../img/places-home-1.svg';
import { Content, SectionContainer } from './Contact';

export default function YouDidIt(props) {
  return (
    <StyledSection>
      <SectionContainer>
        <StyledH2>you did it!</StyledH2>
        <Content>
          <StyledP variant="paragraph">
            Your application is now complete and your admissions decision should
            be ready in 1-2 business days. You should hear from us soon, and
            keep an eye on your Approach Dashboard for any notifications.
          </StyledP>
          <StyledP variant="paragraph">
            Good luck! (We might be biased, but we're really rooting for you.)
          </StyledP>
          <ButtonContainer>
            <DashboardButton as={Link} to="/dashboard/">
              <HomeIcon src={homeImg} />
              <span>Dashboard</span>
            </DashboardButton>{' '}
            <ContactButton />
          </ButtonContainer>
        </Content>
      </SectionContainer>
    </StyledSection>
  );
}

const HomeIcon = styled.img`
  width: 1.25rem;
  margin-right: 0.5rem;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 1.85rem 0;
`;

const DashboardButton = styled(UnstyledButton)`
  background: ${({ theme }) => theme.colors.lightblue};
  color: white;
  width: 9.1875rem;
  font-weight: bold;
  line-height: 0.9375rem;
  font-size: 0.9375rem;
  letter-spacing: 0.021875rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active {
    background: ${({ theme }) => theme.colors.mediumBlue};
    color: white;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.mediumBlue};
    color: white;
  }
`;
