import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import onClickOutside from 'react-onclickoutside';
import { UnstyledButton } from '../Styles/StyledButton';
import { GET_USER } from '../Graphql/queries';
import { handleLogout } from '../utils/auth';
import StyledA from '../Styles/StyledA';
import _get from 'lodash/get';

function HamburgerMenu(props) {
  const { data } = useQuery(GET_USER);
  const handleClick = () => props.setShowMenu(false);
  const name = _get(data, 'user')
    ? `${data.user.first_name} ${data.user.last_name}`
    : '';
  // We could also get their phone number for an application,
  // but that's a bit more complicated.
  HamburgerMenu.handleClickOutside = () => props.setShowMenu(false);
  return (
    <StyledNav>
      <ul>
        {!props.isApplication ? (
          <>
            <Link to="/account">
              <StyledButton onClick={handleClick}>
                <span>Profile</span>
              </StyledButton>
            </Link>

            <StyledButton
              onClick={() => {
                handleLogout();

                handleClick();
              }}
              style={{ borderTop: '1px solid rgba(216, 216, 216, 1' }}
            >
              <span>Logout</span>
            </StyledButton>
          </>
        ) : (
          <>
            <StyledButton
              onClick={() => {
                handleClick();
                // eslint-disable-next-line no-undef
                window.PodiumWebChat.open({ name });
              }}
            >
              <span>Chat</span>
            </StyledButton>
            <StyledA as="a" href="http://help.devmountain.com/">
              <StyledButton
                onClick={handleClick}
                style={{ borderTop: '1px solid rgba(216, 216, 216, 1' }}
              >
                <span>FAQ</span>
              </StyledButton>
            </StyledA>
            <StyledButton
              onClick={() => {
                handleLogout();

                handleClick();
              }}
              style={{ borderTop: '1px solid rgba(216, 216, 216, 1' }}
            >
              <span>Logout</span>
            </StyledButton>
          </>
        )}
      </ul>
    </StyledNav>
  );
}

const onClickOutsideConfig = {
  handleClickOutside: () => HamburgerMenu.handleClickOutside,
};

export default onClickOutside(HamburgerMenu, onClickOutsideConfig);

const StyledNav = styled.nav`
  /* width: 100%; */
  position: absolute;
  right: 0.5rem;
  background: white;
  box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.1875rem;
  z-index: 1;
`;

const StyledButton = styled(UnstyledButton)`
  color: ${({ theme }) => theme.colors.darkestgray};
  text-decoration: none;
  text-align: left;
  width: 100%;
  padding: 1.0625rem 4.0625rem 1.0625rem 1rem;
  :not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightgray};
  }

  &:hover,
  &:active {
    background: ${({ theme }) => theme.colors.mediumgray};
    color: ${({ theme }) => theme.colors.lightblue};
  }
`;
