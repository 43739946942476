import React from 'react';
import styled from 'styled-components';
import Card from '../Shared/Card';
import PageWithArrows from '../img/PageWithArrows';
import CalendarIcon from '../img/CalendarIcon';
import DropPin from '../img/DropPin';
import {ReactComponent as ClockIcon} from '../img/clock-icon.svg';
import Loading from '../Shared/Loading';
import {ADMISSIONS_EMAIL, ADMISSIONS_TELEPHONE} from '../constants'

export default function CohortWidget({ cohort, loading, span }) {
  const isRemote = cohort.isRemote;

  return (
    <Card header="Current Application" defaultExpanded span={span}>
      <StyledSection style={{ position: 'relative' }}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <StyledDiv>
              <IconSpan>
                <PageWithArrows />
              </IconSpan>
              {cohort.programName}
            </StyledDiv>
            <StyledDiv>
              <IconSpan>
                <CalendarIcon />
              </IconSpan>
              {cohort.formattedStartDate} - {cohort.formattedEndDate}
            </StyledDiv>
            <StyledDiv>
              <IconSpan>
                <DropPin />
              </IconSpan>
              {isRemote
                ? cohort.locationName
                : `${cohort.Location_City__c}, ${cohort.Location_State__c}`}
            </StyledDiv>
            {cohort.timeZone && 
            <StyledDiv>
              <IconSpan>
                <ClockIcon />
              </IconSpan>
            Class operates on the {cohort.timeZone} Time Zone schedule
            </StyledDiv>}
            <StyledAside>
              If you need to change your <i>course</i>, <i>course date</i>, or{' '}
              <i>campus</i> location please contact admissions at{' '}
              <a href={`mailto:${ADMISSIONS_EMAIL}`}>
                {ADMISSIONS_EMAIL}
              </a>{' '}
              or{' '}
              <span style={{ whiteSpace: 'nowrap' }}>
                <a href={`tel:+${ADMISSIONS_TELEPHONE.replace(/-/g, '')}`} style={{ textDecoration: 'none' }}>
                  {ADMISSIONS_TELEPHONE}
                </a>
              </span>
              .
            </StyledAside>
          </>
        )}
      </StyledSection>
    </Card>
  );
}

const StyledSection = styled.section`
  padding: 0 1rem 1rem;
  color: ${({ theme }) => theme.colors.midgray};
  font-weight: 500;
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  margin: 1rem 0;
`;

const IconSpan = styled.span`
  box-sizing: content-box;
  width: 0.9375rem;
  height: 1.125rem;
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;
  color: ${({ theme }) => theme.colors.lightblue};
`;

const StyledAside = styled.aside`
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fonts.libFrank};
  a {
    color: ${({ theme }) => theme.colors.lightblue};
    font-weight: 500;
  }
  i {
    font-style: italic;
  }
`;
